import React from 'react'
import LazyLoadImage from '../LazyLoadImage'
import LazyLoadComponent from '../LazyLoadComponent'
import { LinkRenderer } from '../../../lib/utils'
import ReactMarkdown from '../../../lib/ReactMarkdown'

const AssetMedium = element => (
  <div className="row assetmedium-main" id="assetmedium">
    <div className="col-lg-10 assetmedium-image offset-lg-1">
      {element.image.fields.file.contentType.split('/')[0] === 'video' ? (
        <LazyLoadComponent>
          <video
            controls={element.videoAutoplay ? false : 'controls'}
            className="assetmedium-video"
            autoPlay={element.videoAutoplay ? 'autoplay' : false}
            muted={element.videoAutoplay ? 'muted' : false}
            poster={
              element.coverImage &&
              element.coverImage.fields &&
              element.coverImage.fields.file &&
              element.coverImage.fields.file.url
            }
            loop={element.videoAutoplay ? 'loop' : false} >
            <source
              src={element.image.fields.file.url}
              type={element.image.fields.file.contentType} />
          </video>
        </LazyLoadComponent>
      ) : (
        <LazyLoadImage
          asset={element.image.fields}
          className="assetmedium-banner"
          sizes={[
            { viewport: 1400, width: 900 },
            { viewport: 0, width: 600 }
          ]} />
      )}
      {(element.text || element.credit) && (
        <div className="assetmedium-text col-lg-6 offset-lg-3">
          {element.text && (
            <React.Fragment>
              <ReactMarkdown
                source={element.text}
                escapeHtml={false}
                renderers={{ link: LinkRenderer, paragraph: 'span' }} />
            </React.Fragment>
          )}
          {element.credit && (
            <>
              &nbsp;&nbsp;&nbsp;
              <span className="assetmedium-credit">
                {element.credit}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  </div>
)

export default AssetMedium
