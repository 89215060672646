import React from 'react'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class AirCanadaLinks extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      dest: props.dest ? props.dest : '',
      tc: props.tc ? props.tc : ''
    }
  }

  render() {
    // FRONTEND: This only accepts two values on Contentful. To City & To Country
    let tc = ''
    switch (this.state.tc) {
      case 'To City':
        tc = 'ci'
        break
      case 'City to City':
        tc = 'cici'
        break
      case 'To Country':
        tc = 'co'
        break
      case 'Country to Country':
        tc = 'coco'
        break
      case 'From City':
        tc = 'fci'
        break
      case 'From Country':
        tc = 'fco'
        break
      default:
        break
    }

    const localePath = this.context.currentLocale

    let edition = localePath === 'fr' ? 'fr-ca' : 'en-ca'
    var locale
    if (localePath === 'fr') {
      locale = 'fr'
    } else if (localePath === 'en') {
      locale = 'en-US'
    }

    const title  = locale === 'en-US' ? 'Book Flight' : 'VOL À RÉSERVER'

    return (
      <div
        id="title_5"
        className="aircanada-wrapper"
        data-title={title}>
        <a
          className="aircanada"
          target="_blank"
          rel="noopener noreferrer"
          href={
            'https://www.aircanada.com/redirect?edition=' +
            edition +
            '&orig=yyz&dest=' +
            this.state.dest +
            '&tc=' +
            tc
          } >
          <div style={{ visibility: 'hidden', position: 'absolute' }} >
            {title}
          </div>
          <div className="aircanada-main">
            <div className="row">
              <div className="col-lg-6 aircanada-column">
                <div className="aircanada-markdown">
                  <ReactMarkdown
                    source={this.state.text}
                    escapeHtml={false}
                    renderers={{ link: LinkRenderer }}
                  />
                </div>
              </div>
              <div className="col-lg-6 aircanada-column">
                <div className="aircanada-link">
                  {locale === 'en-US'
                    ? 'Fly '
                    : (
                      <span className='french'>Voyagez avec&nbsp;</span>
                    )}
                  <span>Air Canada.</span>
                  <br />
                  <div className="btn-aircanada btn btn-outline-light">
                    {locale === 'en-US' ? 'Book Now' : 'Réservez maintenant'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    )
  }
}

AirCanadaLinks.contextType = sContext

export default AirCanadaLinks
