import React, { memo, useContext } from 'react'
import LazyLoadImage from '../LazyLoadImage'
import LazyLoadComponent from '../LazyLoadComponent'
import { LinkRenderer } from '../../../lib/utils'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import AnchorTag from '../AnchorTag'
import { sContext } from '../../../context'

export const AssetWrapper = memo(({
  link,
  children
}) => {
  const localePath = useContext(sContext).currentLocale

  if(link) {
    return (
      <AnchorTag
        path={link}
        localePath={localePath}>
        {children}
      </AnchorTag>
    )

  } else {
    return (
      <>
        {children} 
      </>
    )
  }
})

const ImageAsset = ({
  image: {
    fields: image
  }
}) => (
  <LazyLoadImage
      asset={image}
      className="assetdiptych-banner"
      sizes={[
        { viewport: 1400, width: 650 },
        { viewport: 0, width: 400 }
      ]} />
)

const VideoAsset = ({
  videoAutoplay,
  coverImage,
  image: { fields: { file: {
    url,
    contentType: imageContentType
  }}}
}) => (
  <LazyLoadComponent>
    <video
      controls={videoAutoplay ? false : 'controls'}
      className="assetdiptych-video"
      autoPlay={videoAutoplay ? 'autoplay' : false}
      muted={videoAutoplay ? 'muted' : false}
      loop={videoAutoplay ? 'loop' : false}
      poster={coverImage?.fields?.file?.url} >
      <source
        src={url}
        type={imageContentType} />
    </video>
  </LazyLoadComponent>
)

const Text = ({ text }) => (
  <ReactMarkdown
    source={text}
    escapeHtml={false}
    renderers={{
      link: LinkRenderer,
      paragraph: 'span'
    }} />
)

const Credit = ({ credit }) => (
  <>
    <span className="assetdiptych-credit">
      {credit}
    </span>
  </>
)

const AssetDiptych = memo(({
  firstImage,
  secondImage,
  text,
  credit,
  videoAutoplay,
  coverImage,
  leftLink,
  rightLink
}) => {
  const firstText = text && text.split('\n')[0]
  const secondText = text && text.split('\n')[1]
  const firstCredit = credit && credit.split(',')[0]
  const secondCredit = credit && credit.split(',')[1]

  return (
    <div className="row assetdiptych-main" id="assetmedium">
      <div className="col-lg-5 assetdiptych-image offset-lg-1">
        <AssetWrapper link={leftLink} >
          {firstImage.fields.file.contentType.split('/')[0] === 'video' ? (
            <VideoAsset
              videoAutoplay={videoAutoplay}
              coverImage={coverImage}
              image={firstImage} />
          ) : (
            <ImageAsset image={firstImage} />
          )}
          {(firstText || firstCredit)  && (
            <div className="assetdiptych-text">
              {firstText && <Text text={firstText} /> }
              {firstText && firstCredit && <> &nbsp;&nbsp;&nbsp; </>}
              {firstCredit && <Credit credit={firstCredit} /> }
            </div>
          )}
        </AssetWrapper>
      </div>
      <div className="col-lg-5 assetdiptych-image">
        <AssetWrapper link={rightLink} >
          {firstImage.fields.file.contentType.split('/')[0] === 'video' ? (
            <VideoAsset
              videoAutoplay={videoAutoplay}
              coverImage={coverImage}
              image={secondImage} />
          ) : (
            <ImageAsset image={secondImage} />
          )}
          {(secondText || secondCredit)  && (
            <div className="assetdiptych-text">
              {secondText && <Text text={secondText} /> }
              {secondText && secondCredit && <> &nbsp;&nbsp;&nbsp; </>}
              {secondCredit && <Credit credit={secondCredit} /> }
            </div>
          )}
        </AssetWrapper>
      </div>
    </div>
  )
})

export default AssetDiptych
