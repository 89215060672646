import React from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const Top404 = ({
  title,
  subtitle,
  image: {
    fields: image
  }
}) => {
  const width = typeof window !== 'undefined' && window.innerWidth > 768 ? 2048 : 1400;

  return (
    <LazyLoadBgImage
      className='row top-404'
      asset={image || {}}
      overrides={{ w: width, q: 90 }}
      gradient="radial" >
      <div className="col-lg-12">
        <h1>{title}</h1>
        <h2>
          <ReactMarkdown
            escapeHtml={false}
            source={subtitle}
            renderers={{ link: LinkRenderer }} />
        </h2>
      </div>
    </LazyLoadBgImage>
  )
}

export default Top404