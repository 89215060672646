import React, { useState, useEffect } from 'react'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const pad = (number, size) => {
  let string = String(number)
  while (string.length < (size || 2)) {
    string = `0${string}`
  }
  return string
}

const AudioPlaylist = ({ subSections }) => {
  const [state, setState] = useState({
    play: [],
    currentTime: 0,
    duration: 0,
    barPercentage: 0,
    audioFiles: [],
    activePosition: null,
    activeCurrentAlbum: null,
    hoverCurrentAlbum: null
  })

  function toggleMute() {
    const { audioFiles } = state

    if (audioFiles[0].muted) {
      audioFiles.map(file => (file.muted = false))
    } else {
      audioFiles.map(file => (file.muted = true))
    }

    setState(prevState => ({
      ...prevState,
      audioFiles: audioFiles
    }))
  }

  function toggleAlbum(index, hoverOn) {
    if (typeof document !== 'undefined' && 'ontouchstart' in document.documentElement) {
      return
    }
    const { albumCover } = subSections[index].fields

    let hoverCurrentAlbum
    if (hoverOn) {
      if (!albumCover) {
        hoverCurrentAlbum = 'hide'
      } else {
        hoverCurrentAlbum = albumCover.fields.file.url
      }
    } else {
      hoverCurrentAlbum = null
    }

    setState(prevState => ({
      ...prevState,
      hoverCurrentAlbum
    }))
  }

  function togglePlay(index) {
    var pauseElement = document.querySelector('.audioembedded-play#pause')
    if (pauseElement) {
      pauseElement.click()
    }
    const { play, audioFiles } = state
    const { title, artist, albumCover } = subSections[index].fields

    let newPlays = play
    let currentPlayStatus
    let newAudioFiles = audioFiles

    for (let i = 0; i < audioFiles.length; i++) {
      if (i !== index) {
        newPlays[i] = false
        audioFiles[i].pause()
        audioFiles[i].currentTime = 0
      } else {
        newPlays[i] = !(activePosition === index)
        currentPlayStatus = newPlays[i]
      }
    }

    if (currentPlayStatus) {
      // Play
      audioFiles[index].play()
      var main = document.querySelector('.audiosticky-main')
      if (main && main.style.display === 'none') {
        main.style.display = 'block'
      }
    } else {
      // Pause
      audioFiles[index].pause()
    }

    Array.from(document.querySelectorAll('.audiosticky-text span')).forEach(el => el.textContent = title)
    Array.from(document.querySelectorAll('.audiosticky-text font')).forEach(el => el.textContent = artist ? ' — ' + artist : '')
    Array.from(document.querySelectorAll('.audiosticky-play')).forEach(el => el.setAttribute('id', newPlays[index] ? 'pauses' : 'plays'))

    setState(prevState => ({
      ...prevState,
      play: newPlays,
      audioFiles: newAudioFiles,
      activePosition: currentPlayStatus ? index : null,
      activeCurrentAlbum:
        currentPlayStatus && albumCover ? albumCover.fields.file.url : null
    }))
  }

  useEffect(() => {
    const { audioFiles } = state
    const newAudioFiles = audioFiles

    if (audioFiles.length <= 0) {
      for (let i = 0; i < subSections.length; i++) {
        const newAudio = typeof window !== 'undefined' ?
          new Audio(subSections[i].fields.audioFile.fields.file.url) :
          {}
        newAudioFiles.push(newAudio)
      }
    }

    for (let i = 0; i < newAudioFiles.length; i++) {
      newAudioFiles[i].addEventListener('canplay', event => {
        const { duration } = event.target
        setState(prevState => ({
          ...prevState,
          duration
        }))
      })

      newAudioFiles[i].addEventListener('timeupdate', event => {
        const { currentTime, duration } = event.target
        if(currentTime === duration) {
          const { play } = state
          const lastIndex = play.length - 1
          if(play[lastIndex]) {
            const newPlay = play.map(_ => false)
            var main = document.querySelector('.audiosticky-main')
            if (main) {
              main.style.display = 'none'
            }
            setState(prevState => ({
              ...prevState,
              play: newPlay,
              activePosition: null
            }))

          } else {
            var next = document.querySelector('.audiosticky-next')
            next.click()
          }

        } else {
          setState(prevState => ({
            ...prevState,
            currentTime,
            duration,
            barPercentage: (currentTime / duration) * 100
          }))
        }

      })
    }

    Array.from(document.querySelectorAll('.audiosticky-mute')).forEach(el => el.addEventListener('click', toggleMute.bind(this)))
  }, [])

  function PlayProgress({ className, index }) {
    return (
      <div className={`col-lg-2 cPlaylist-play-progress ${className}`}>
        <div className="xCircleProgress">
          <CircularProgressbarWithChildren
            value={
              typeof window !== 'undefined' &&
              window.innerWidth <= 991
                ? 0
                : activePosition === index && barPercentage
            }
            styles={{
              path: {
                stroke: play[index] ? '#ae1722' : '#000000',
                strokeLinecap: 'butt',
                strokeWidth: '2px'
              },
              trail: {
                stroke: 'transparent'
              }
            }}
          >
            <div
              className="xCircleProgress-button"
              id={play[index] ? 'pause' : 'play'}
              onClick={() => togglePlay(index)}
            />
          </CircularProgressbarWithChildren>
        </div>
      </div>
    )
  }

  const {
    barPercentage,
    play,
    activePosition,
    activeCurrentAlbum,
    hoverCurrentAlbum
  } = state

  return (
    <div className="row cPlaylist">
      <div className="col-lg-8 offset-lg-1">
        {subSections.map((data, i) => {
          return (
            <div
              key={`audioPlaylist-parent-${i}`}
              className={`row cPlaylist-item ${
                activePosition === i ? 'active' : ''
              }`}
              id={play[i] ? 'pause' : 'play'}
              onMouseOver={() => toggleAlbum(i, true)}
              onMouseOut={() => toggleAlbum(i, false)}
              onClick={() => togglePlay(i)}
            >
              <div className="col-lg-12 cPlaylist-list">
                <PlayProgress className="before" index={i} />
                <div className="col-lg-2 cPlaylist-play-number">
                  {pad(i + 1)}
                </div>
                <div className="col-lg-10 cPlaylist-title-label">
                  {data.fields.title}
                  <PlayProgress className="after" index={i} />
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="col-lg-2 cPlaylist-album">
        {hoverCurrentAlbum !== 'hide' &&
        (activeCurrentAlbum || hoverCurrentAlbum) ? (
          <img
            className="cPlaylist-album-image"
            src={hoverCurrentAlbum || activeCurrentAlbum}
            alt=""
          />
        ) : null}
      </div>
    </div>
  )
}

export default AudioPlaylist