import React, { useMemo, memo } from 'react';
import Fade from 'react-reveal/Fade'

const FullWidthFade = memo((props) => {
  const {
    children,
    module,
    cascade,
    fade = true,
    textType = null,
    addedClass = '',
    noTopPadding,
    isHidePage,
    isEmbededZoom
  } = props

  const [isTopArticle, isStickyPlaylist] = useMemo(() => {
    const isTopArticle = module === 'ArticleTop' ||
      module === 'articleArticleTopNoImage' ||
      module === 'article-top-portrait' ||
      module === 'article-top-no-image' ||
      module === 'article-top-hero-image-video' ||
      module === 'module404Top'

    const isStickyPlaylist =
      module === 'article-audio-player-sticky' ||
      module === 'articleAudioPlayerSticky'

    return [isTopArticle, isStickyPlaylist];
  }, [module]);

  return (
    <div
      className={`
        full-width-fade
        full-width-fade--inner
        ${isHidePage ? 'is-hide-page' : ''}
        ${isTopArticle || noTopPadding ? 'no-top-spacing' : ''}
        ${isStickyPlaylist ? 'is-sticky-playlist' : ''}
        ${addedClass}
      `}
      data-module={module}
      data-texttype={textType}
      // data-menutitle={menuTitle}
      data-isembededzoom={isEmbededZoom} >
      <div className="full-width-fade--inner">
        {fade ?
          <Fade cascade={cascade}>
            {children}
          </Fade>
        : children}
      </div>
    </div>
  )
});

export default FullWidthFade