import React from 'react'
import { LinkRenderer } from '../../../lib/utils'
import LazyLoadImage from '../LazyLoadImage'
import LazyLoadComponent from '../LazyLoadComponent'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class AssetBigSmall extends React.PureComponent {
  state = {
    credits: [],
    returnhtml: []
  }

  displayMore(images, text, credit) {
    let returnhtml = []
    let credits = []
    var colPair = [3, 5]
    if (images && images.length && images.length > 2) {
      for (var i = 2; i < images.length; i += 2) {
        returnhtml.push(
          <div className={'col-lg-' + colPair[0] + ' offset-lg-1'}>
            <img src={images[i - 1] && images[i - 1].fields.file.url} alt="" />
          </div>
        )
        returnhtml.push(
          <div className={'col-lg-' + colPair[1]}>
            <img src={images[i] && images[i].fields.file.url} alt="" />
          </div>
        )
        credits.push(
          <div className="assetbigsmall-text col-lg-6 offset-lg-3">
            <ReactMarkdown
              source={text && text.split('\n')[i] && text.split('\n')[i]}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }} />
            {credit.split(',')[i] && (
              <>
                &nbsp;&nbsp;&nbsp;
                <span className="assetbigsmall-credit">
                  {credit.split(',')[i]}
                </span>
              </>
            )}
          </div>
        )
        credits.push(
          <div className="assetbigsmall-text col-lg-6 offset-lg-3">
            {text && text.split('\n')[i + 1] && text.split('\n')[i + 1]}
            {credit.split(',')[i + 1] && (
              <>
                &nbsp;&nbsp;&nbsp;
                <span className="assetbigsmall-credit">
                  {credit.split(',')[i + 1]}
                </span>
              </>
            )}
          </div>
        )
        var temp = colPair[0]
        colPair[0] = colPair[1]
        colPair[1] = temp
      }
    }
    this.setState({
      credits: credits,
      returnhtml: returnhtml
    })
  }

  componentWillMount() {
    this.displayMore(this.props.images, this.props.text, this.props.credit)
  }

  render() {
    return (
      <div className="row assetbigsmall-main">
        <div className="col-lg-12">
          <div className="row assetbigsmall-image">
            <div className="col-lg-6 offset-lg-1">
              {(this.props.image.fields.file.contentType.split('/')[0] ===
                'video' && (
                <LazyLoadComponent>
                  <video
                    controls={this.props.videoAutoplay ? false : 'controls'}
                    className="assetdiptych-video"
                    autoPlay={this.props.videoAutoplay ? 'autoplay' : false}
                    muted={this.props.videoAutoplay ? 'muted' : false}
                    poster={
                      this.props.coverImage &&
                      this.props.coverImage.fields &&
                      this.props.coverImage.fields.file &&
                      this.props.coverImage.fields.file.url
                    }
                    loop={this.props.videoAutoplay ? 'loop' : false}
                  >
                    <source
                      src={this.props.image.fields.file.url}
                      type={this.props.image.fields.file.contentType}
                    />
                  </video>
                </LazyLoadComponent>
              )) || (
                <LazyLoadImage
                  asset={this.props.image.fields}
                  className="assetdiptych-banner"
                  sizes={[
                    { viewport: 1400, width: 650 },
                    { viewport: 0, width: 400 }
                  ]}
                ></LazyLoadImage>
              )}
            </div>
            {this.props.images && this.props.images.length && (
              <div className="col-lg-4">
                {(this.props.images[0].fields.file.contentType.split('/')[0] ===
                  'video' && (
                    <LazyLoadComponent>
                    <video
                      controls={this.props.videoAutoplay ? false : 'controls'}
                      className="assetdiptych-video"
                      autoPlay={this.props.videoAutoplay ? 'autoplay' : false}
                      muted={this.props.videoAutoplay ? 'muted' : false}
                      loop={this.props.videoAutoplay ? 'loop' : false}
                      poster={
                        this.props.coverImage &&
                        this.props.coverImage.fields &&
                        this.props.coverImage.fields.file &&
                        this.props.coverImage.fields.file.url
                      }
                    >
                      <source
                        src={this.props.images[0].fields.file.url}
                        type={this.props.images[0].fields.file.contentType}
                      />
                    </video>
                    </LazyLoadComponent>
                )) || (
                  <LazyLoadImage
                    asset={this.props.images[0].fields}
                    className="assetdiptych-banner"
                    sizes={[
                      { viewport: 1400, width: 650 },
                      { viewport: 0, width: 400 }
                    ]}
                  ></LazyLoadImage>
                )}
              </div>
            )}
            {this.state.returnhtml}
          </div>
          <div className="assetbigsmall-text col-lg-6 offset-lg-3">
            {this.props.text && this.props.text.split('\n')[0] && (
              <ReactMarkdown
                source={this.props.text.split('\n')[0]}
                escapeHtml={false}
                renderers={{ link: LinkRenderer, paragraph: 'span' }} />
            )}
            {this.props.credit && this.props.credit.split(',')[0] && (
              <>
                &nbsp;&nbsp;&nbsp;
                <span className="assetbigsmall-credit">
                  <ReactMarkdown
                    source={this.props.credit.split(',')[0]}
                    escapeHtml={false}
                    renderers={{ link: LinkRenderer, paragraph: 'span' }} />
                </span>
              </>
            )}
          </div>
          <div className="assetbigsmall-text col-lg-6 offset-lg-3">
            {this.props.text && this.props.text.split('\n')[1] && (
              <ReactMarkdown
                source={this.props.text.split('\n')[1]}
                escapeHtml={false}
                renderers={{ link: LinkRenderer, paragraph: 'span' }} />
            )}
            {this.props.credit && this.props.credit.split(',')[1] && (
              <>
                &nbsp;&nbsp;&nbsp;
                <span className="assetbigsmall-credit">
                  <ReactMarkdown
                    source={this.props.credit.split(',')[1]}
                    escapeHtml={false}
                    renderers={{ link: LinkRenderer, paragraph: 'span' }} />
                </span>
              </>
            )}
          </div>
          <ReactMarkdown
            source={this.state.credits}
            escapeHtml={false}
            renderers={{ link: LinkRenderer, paragraph: 'span' }} />
        </div>
      </div>
    )
  }
}

export default AssetBigSmall
