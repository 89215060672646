import React, { useContext } from 'react';
import Masonry from 'react-masonry-css'
import LazyLoadImage from './LazyLoadImage'
import Fade from 'react-reveal/Fade'
import { sContext } from '../../context'
import ReactMarkdown from '../../lib/ReactMarkdown';

const StoryFeedGrid = ({
  entries,
  hideMore
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div className='cStoryFeedGrid-wrapper'>
      <div
        className={`row latest-articles ${hideMore ? 'expanded' : ''}`}
        style={{ gridAutoRows: '31px' }} >
        <Masonry
          breakpointCols={{
            default: 4,
            992: 1,
          }}
          className="my-masonry-grid"
          columnClassName="latest-article-wrapper">
          {entries.map(({ fields: {
            hasSponsor,
            formatedPath,
            title,
            subtitle,
            asset,
            articleType
          }}, i) => (
              <div
                key={`StoryFeed-${i}`}
                className={`
                  latest-article-container
                  ${hasSponsor ? 'is-a-sponsored' : ''}
                `} >
              <Fade>
                <div className="latest-article-content">
                  <div className="latest-article-item">
                    {asset && (
                      <a
                        className="latest-link"
                        href={`/${localePath}/${formatedPath}/`} >
                        <LazyLoadImage
                          asset={asset}
                          className="latest-image"
                          sizes={[
                            { viewport: 992, width: 350 },
                            { viewport: 0, width: 500 }
                          ]} />
                      </a>
                    )}
                    <h4 className="latest-type">
                      {hasSponsor ? (
                        <p>{hasSponsor}</p>
                      ) : (
                        <a
                          className="latest-link"
                          href={`/${localePath}/category/?aggTag=${articleType}`} >
                          {' '}{articleType}{' '}
                        </a>
                      )}
                    </h4>
                    <a
                      className="latest-link"
                      href={`/${localePath}/${formatedPath}/`} >
                      <div className="latest-text">
                        <span className={asset ? 'latest-header' : 'latest-header-large'}>
                          <ReactMarkdown
                            source={title}
                            renderers={{
                              text: (node) => {
                                return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2011$2')
                              }
                            }}
                            escapeHtml={false} />
                        </span>
                        {subtitle &&
                          <ReactMarkdown
                            source={` — ${subtitle}`}
                            renderers={{
                              text: (node) => {
                                return `${node.value}`.replace(/(\S+)-(\S+)/g,'$1\u2011$2')
                              }
                            }}
                            escapeHtml={false} />
                        }
                      </div>
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  )
}

export default StoryFeedGrid