import React, { useContext} from 'react'
import { sContext } from '../../../context'
import SocialShare from '../SocialShare'

const TopShare = (props) => {
  const localePath = useContext(sContext).currentLocale
  
  return (
    <div className="cTopShare">
      <div className="cTopShare-title">
        {localePath === 'en' ? 'Share' : 'Partagez'}
      </div>
      <SocialShare {...props} />
    </div>
  )
}

export default TopShare
