import React, { useState, useEffect, useContext, useCallback, useRef } from 'react'
import { getEntries } from '../../../lib/client'
import LazyLoadImage from '../LazyLoadImage'
import Fade from 'react-reveal/Fade'
import { sContext } from '../../../context'
const months = {
  fr: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
}
const dateToString = (date, locale) => {
  const newDate = new Date(date)
  return `${months[locale === 'fr' ? 'fr' : 'en'][newDate.getMonth()]} ${newDate.getFullYear()}`
}

const IssuesGrid = () => {
  const mounted_ref = useRef(true)
  const localePath = useContext(sContext).currentLocale
  const [data, setData] = useState([])
  const [expanded, setExpanded] = useState(false)

  const runEffect = useCallback( async () => {
    const addedProperties = {
      'fields.tags[in]': 'digital magazine',
      order: '-fields.published_at,-sys.createdAt',
      include: 3
    }

    const pagesEntries = await getEntries({
      content_type: 'page',
      ...addedProperties
    }, localePath)

    const articlesEntries = await getEntries({
      content_type: 'article',
      ...addedProperties
    }, localePath)

    if(!mounted_ref.current) return

    const entries = [...pagesEntries.items, ...articlesEntries.items].sort((a,b) => {
      const aDate = (new Date(a.fields.published_at)).getTime()
      const bDate = (new Date(b.fields.published_at)).getTime()
      return bDate - aDate
    })

    const tempEntries = []
    const array = entries || []
    array.forEach(item => tempEntries.push(item))
    setData(tempEntries)
  }, [localePath])
  
  useEffect(() => {
    runEffect()
    return () => {
      mounted_ref.current = false
    }
  }, [])
  
  return (
    <div
      className="cArticleGrid"
      data-expanded={expanded ? '1' : null} >
      <div
        className="cArticleGrid-inner"
        data-loading={!!!data} >
        {data.map(({
          fields: {
            path: mPath,
            banner,
            overrideBanner,
            published_at,
            title,
            content
          }
        }, i) => {
          const path = '/' + localePath + '/article/' + mPath
          let asset

          let selectedTitle = title

          if(banner) {
            asset = banner.fields
            
          } else if(overrideBanner) {
            asset = overrideBanner.fields

          } else {
            const pageContent = content?.content[0].data.target.fields ?? {}
            
            if(pageContent.overrideBanner) {
              asset = pageContent.overrideBanner.fields
              selectedTitle = content?.content[0].data.target.fields.title

            } else if(pageContent.image) {
              asset = pageContent.image.fields
            }
          }

          return (
            <a
              key={`cArticleGrid-${i}`}
              href={path}
              className="cArticleGrid-item" >
              <div className="cArticleGrid-itemInner">
                <Fade>
                  <LazyLoadImage 
                    className="cArticleGrid-itemImage" 
                    sizes={[
                      { viewport: 992, width: 350 },
                      { viewport: 0, width: 500 }
                    ]}
                    asset={asset} />
                  {published_at ?
                    <div className="cArticleGrid-itemDate">
                      {dateToString(published_at, localePath)}
                    </div>
                  : null}
                  <div className="cArticleGrid-itemTitle">
                    {selectedTitle}
                  </div>
                </Fade>
              </div>
            </a>
          )
        })}
        <button
          className="cArticleGrid-expand"
          onClick={() => setExpanded(true)}>
            {localePath === 'fr' ? 'Plus' : 'More' }
        </button>
      </div>
    </div>
  )
}

export default IssuesGrid