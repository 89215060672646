import React, { useContext } from 'react'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'
const months = {
  fr: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
  en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
}
const IssueHeadline = ({
  text,
  published_at,
  categoryInformation
}) => {
  const localePath = useContext(sContext).currentLocale
  let publishedDate

  if(published_at) {
    
    publishedDate = new Date(publishedDate)
    
    const date = new Date(published_at.split('T')[0])
    date.setDate(date.getDate() + 7);
    publishedDate = `${months[localePath === 'fr' ? 'fr' : 'en'][date.getMonth()]} ${date.getFullYear()}`
  }

  return (
    <div className={`cIssueHeadline ${!publishedDate ? 'cIssueHeadline-grid' : ''}`}>
      
      <div className='cIssueHeadline-text'>
        {publishedDate ? (
          <div className='cIssueHeadline-published'>
            {publishedDate}
          </div>
        ) : null }
        <ReactMarkdown renderers={{ link: LinkRenderer }} source={text} />
        {categoryInformation && (
          <ReactMarkdown renderers={{ link: LinkRenderer }} source={categoryInformation.description} />
        )}
        {publishedDate ? (
          <div className='cIssueHeadline-explore'>
            <a href={`/${localePath}/magazine`}>
              {localePath === 'en' ? 'Explore More Issues' : 'Découvrez d’autres numéros'}
            </a>
          </div>
        ) : null }
      </div>
    </div>
  )
}

export default IssueHeadline