import React from 'react'
import RichTextRenderer from './components/RichTextRenderer'
import { colorPicker, getContrast } from '../lib/utils'
import { sContext } from '../context'
import Navigation from './components/Navigation'
import Footer from './components/Footer'
import FloatingMenu from './components/FloatingMenu'
import Ads from './components/Ads'
import FullWidthFade from './components/FullWidthFade'

class Article extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasAds: true
    }

    const {
      sections,
      content
    } = props.pages

    this.menuItemRefs = [React.createRef()]

    if(sections) {
      props.pages.sections.map(section => {
        if(section?.fields) {
          const { fields: {
            menu_title
          }} = section
  
          if(menu_title) {
            this.menuItemRefs.push(React.createRef())
          }
        }
      })

    } else if(content) {
      content.content.map(section => {
        if(section.data.target?.fields?.menuTitle) {
          this.menuItemRefs.push(React.createRef())
        }
      })
    }

    this.getMetaKeywords = () => {
      const {
        pages: {
          metaKeywords,
          sections = [],
          tags
        }
      } = props
      
      try {
        if (metaKeywords) return metaKeywords.split(',').map(t => `${t}`.trim())
        const matchingTaglists = []
        let allTags = []

        if(tags.length) {
          tags.forEach(tag => matchingTaglists.push(tag))
        }

        sections
          .filter(v => v.fields && v.fields.tags && v.fields.tags.length)
          .forEach(v => matchingTaglists.push(v.fields.tags))

        sections
          .filter(v => v.fields && v.fields.subSections)
          .forEach(section => {
            section.fields.subSections
              .filter(v => v.fields && v.fields.tags && v.fields.tags.length)
              .forEach(v => matchingTaglists.push(v.fields.tags))
          })

        if(tags.length) {
          allTags = [...matchingTaglists]

        } else {
          matchingTaglists.forEach(tags => {
            tags
            .filter(v => allTags.indexOf(v) === -1)
            .forEach(v => allTags.push(v))
          })
        }

        return allTags && allTags.length ? allTags.slice(0, 50) : ['Air Canada']

      } catch (e) {
        return ['Air Canada']
      }
    }
  }
  updateListItems() {
    if (typeof window !== 'undefined') {
      const allListItems = document.querySelectorAll('.body-text-content ol > li');
      allListItems.forEach(element => {
        if (!element || !element.textContent) {
          element.classList.add('hide-list')
        }
      })
    }
  }
  componentDidUpdate() {
    this.updateListItems()
  }
  componentDidMount() {
    this.updateListItems();
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        const sasA = document.querySelectorAll('#sas_84785 a')
        if(sasA.length <= 0 && window.sas !== undefined && window.globalNavPadding) {
          this.setState({ hasAds: false })
        }
      }, 2000)
    }
  }

  //Render
  render() {
    const {
      menuItemRefs,
      state: {
        hasAds
      },
      props: {
        navigation,
        secondLangPath,
        searchedGuides = [],
        pagePath,
        latestIds,
        footer,
        paths,
        pageId,
        categoryInformation,
        pages,
        pages: {
          content,
          sections,
          navigationColor,
          navigationDefaultColor = 'white-white',
          pageBgColor,
          backgroundColour
        }
      },
      context: {
        location
      }
    } = this

    const isEmbededZoom = (
      location.pathname.includes('dCjRynI8jP2UCs9tXS15u') ||
      location.pathname.includes('webinar-food-canada') ||
      location.pathname.includes('webinaire-gastronomie-canada')
    )

    const isWebinar = (
      isEmbededZoom ||
      location.pathname.includes('webinar-travel-canada') ||
      location.pathname.includes('webinaire-voyage-canada') ||
      location.pathname.includes('677tuKnoououoSiJ82doFg')
    )

    let colorStyles;
    if(pageBgColor) {
      colorStyles = {
        backgroundColor: pageBgColor,
        color: getContrast(pageBgColor)
      }

    } else if(backgroundColour) {
      colorStyles = {
        backgroundColor: colorPicker(backgroundColour),
        color: getContrast(backgroundColour)
      }

    } else {
      colorStyles = {
        backgroundColor: 'white',
        color: 'black'
      }
    }

    const backgroundType = colorStyles.color !== 'black' ? 'dark' : 'light'

    
    return (
      <div
        id="article"
        className={`
          container-fluid
          ${location.pathname.includes('/404') ? 'is-404' : ''}
          ${pageBgColor ? 'has_custom-bg-color' : ''}
          ${backgroundType}-background
          ${!hasAds ? 'no-ads' : ''}
        `}
        style={{ ...colorStyles }} >
        <div className="top-ad-wrapper">
          <Ads
            page='homepage'
            counter={1} />
        </div>
        <div className="main-wrapper">

          {/* // FRONTEND Is hidePage used for CBNR? */}
          {!pages.hidePage && (
            <Navigation
              navigationColor={navigationColor}
              default={navigationDefaultColor}
              paths={paths || {}}
              menu={navigation}
              secondLangPath={secondLangPath}
              forceShowCategories={
                pagePath === 'magazine' || location.pathname.includes('magazine/')
              }
              searchedGuides={searchedGuides}
              {...colorStyles}
            />
          )}
          <main>
            <RichTextRenderer
              content={content}
              pages={pages}
              getMetaKeywords={this.getMetaKeywords().shift()}
              sections={sections}
              refs={menuItemRefs}
              isEmbededZoom={isEmbededZoom}
              latestIds={latestIds}
              pageId={pageId}
              moduleOverrides={this.props.moduleOverrides || {}}
              categoryInformation={categoryInformation} />

            {/* // FRONTEND Is this needed for CBNR? */}
            {isWebinar && (
              <FullWidthFade
                hardColor={true}
                noTopPadding
                pageBg='#FAFAFA' >
                <div className="powered-by">
                  <p>
                    {
                      location.pathname.split('/')[1] ==='en' ?
                      'Powered By' : 'Contenu créé par'
                    }
                  </p>
                  <div />
                </div>
              </FullWidthFade>
            )}

            {!pages.hidePage && (
              <>
                <Footer
                  footer={footer}
                  location={location} />

                {(menuItemRefs && menuItemRefs[0].current) && <FloatingMenu refs={menuItemRefs} />}
              </>
            )}

            {pages.hidePage && <div className='hidePage_hack'/>}
          </main>
        </div>
      </div>
    )
  }
}

Article.contextType = sContext

export default Article
