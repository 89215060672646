import React, { useContext, useEffect, useState, useRef } from 'react'
import { getEntries } from '../../lib/client'
import { formatArticleElement } from '../../lib/utils'
import StoryFeedGrid from './StoryFeedGrid'
import { sContext } from '../../context'
import { getLandscapeAsset } from '../../lib/getAssets'

const MagazineStoryFeed = ({
  pageId,
  entriesId
}) => {
  const scrollRef = useRef(null)
  const mounted_ref = useRef(true)
  const contentfulEnv = useContext(sContext).contentfulEnv
  const localePath = useContext(sContext).currentLocale
  const [entries, setEntries] = useState([])
  const [inView, setInView] = useState(false)
  
  const runEffect = async () => {
    if (typeof window !== 'undefined' && window.addEventListener) {
      window.addEventListener('scroll', handleOnScroll)
    }

    const pageQuery = {
      'content_type': 'page',
      'fields.content[exists]': true,
      'fields.hidePage[ne]': true,
      'sys.id[nin]': pageId,
      'sys.id[in]':  entriesId.join(','),
      order: '-fields.published_at,-sys.createdAt'
    }

    const data = await getEntries(pageQuery, localePath, contentfulEnv)
    const { items } = data

    if(!mounted_ref.current) return
    
    let _entries = []

    for (let i = 0; i < items.length; i++) {
      const page = items[i]
      const entry = formatArticleElement(page)

      if(entry.category) {
        entry.formatedPath = `${entry.category.fields.path}/${entry.path}`
        
      } else {
        entry.formatedPath = `article/${entry.path}`
      }
      
      const articleType = entry.articleType || entry.type
      entry.articleType = articleType

      let banner
      let overrideBanner

      if(entry.overrideBanner) {
        overrideBanner = entry.overrideBanner
        
      } else if(entry.banner) {
        banner = entry.banner

      } else {
        const pageContent = entry.fields?.content?.content[0].data.target.fields ?? {}
        
        if(pageContent.overrideBanner) {
          overrideBanner = pageContent.overrideBanner

        } else if(pageContent.image) {
          banner = pageContent.image
        }
      }

      entry.asset = getLandscapeAsset({
        topType: articleType,
        banner,
        overrideBanner,
        inArticleAsset: entry.inArticleAsset,
        landscapeDesktopAsset: entry.landscapeDesktopAsset
      })
      
      if(entry.sponsored_by) {
        entry.hasSponsor = entry.sponsored_by

      } else if(articleType && articleType.includes('Air Canada enRoute')) {
        entry.hasSponsor = articleType
      }

      _entries.push({ fields: entry })
    }

    setEntries(_entries)
  }
  
  useEffect(() => {
    mounted_ref.current = true
    runEffect()

    return _ => {
      mounted_ref.current = false
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleOnScroll)
      }
    }
  }, [])

  const handleOnScroll = () => {
    const rect = (!inView && scrollRef && scrollRef.current && scrollRef.current.getBoundingClientRect()) || null
    if (!rect) {
      return
    }
    const scroll = (window.scrollY + window.innerHeight) * 1.25
    if (scroll >= rect.top) {
      setInView(true)
    }
  }

  if (!inView) {
    return <div ref={scrollRef} />
    
  } else {
    return (
      <div className="row latest-main">
        <div className="col-lg-12">
          <StoryFeedGrid entries={entries} />
        </div>
      </div>
    )
  }
}
  

export default MagazineStoryFeed