import React, { useEffect } from 'react'

let lastIndex = null

const AudioPlayerSticky = () => {
  
  useEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 991) {
      var play = document.querySelector('.audiosticky-play')
      var sound = document.querySelector('.audiosticky-sound')
      if (play && sound) {
        sound.appendChild(play)
      }
    }

    let audios = Array.from(document.querySelectorAll('.audioembedded-play, .cPlaylist-item'))
    audios.forEach(el => el.addEventListener('click', (evt) => {
      var target = evt.currentTarget
      lastIndex = target.getAttribute('id') === 'pause' ?
        getAudioIndex(audios) : null
    }))

    document.querySelector('.audiosticky-play').addEventListener('click', () => {
      lastIndex = document.querySelector('.audiosticky-play').getAttribute('id') === 'pauses' ?
        getAudioIndex(audios) : null

      var title = Array.from(document.querySelectorAll('.audioembedded-title')).find(el => el.textContent && el.textContent.indexOf(document.querySelector('.audiosticky-text span').textContent) !== -1)
      var label = Array.from(document.querySelectorAll('.cPlaylist-title-label')).find(el => el.textContent && el.textContent.indexOf(document.querySelector('.audiosticky-text span').textContent) !== -1)

      if (title) {
        title.parentNode
        .previousSibling
        .click()
      }
      if (label) {
        label.parentNode
        .parentNode
        .click()
      }
    })

    document.querySelector('.audiosticky-next').addEventListener('click', () => {
      let audios = Array.from(document.querySelectorAll('.audioembedded-play, .cPlaylist-item'))
      let currentIndex = getAudioIndex(audios)
      let nextIndex
      if (currentIndex >= audios.length - 1) {
        nextIndex = 0
      } else {
        nextIndex = currentIndex + 1
      }

      audios[nextIndex].click()
    })

    document.querySelector('.audiosticky-prev').addEventListener('click', () => {
      let audios = Array.from(document.querySelectorAll('.audioembedded-play, .cPlaylist-item'))
      let currentIndex = getAudioIndex(audios)
      let prevIndex

      if (currentIndex <= 0) {
        prevIndex = audios.length - 1
      } else {
        prevIndex = currentIndex - 1
      }
      audios[prevIndex].click()
    })
  }, [])

  function getAudioIndex(audios) {
    let index

    audios.map((val, i) => {
      if (val.getAttribute('id') === 'pause') {
        index = i
      }
      return undefined
    })

    if (index === undefined && lastIndex !== null) {
      index = lastIndex
    }

    return index
  }

  return (
    <div style={{ display: 'none' }} className="row audiosticky-main">
      <div className="col-lg-8 offset-2 audiosticky-box">
        <div className="row">
          <div className="col-lg-3 audiosticky-controls">
            <div className="audiosticky-prev" />
            <div className="audiosticky-play" id="plays" />
            <div className="audiosticky-next" />
          </div>

          <div className="col-lg-6 audiosticky-text">
            <span style={{ display: 'contents' }}></span>{' '}
            <font style={{ display: 'contents' }}></font>
          </div>

          <div className="col-lg-3 audiosticky-sound">
            <div
              className="audiosticky-mute"
              click={() =>
                document.querySelector('.audiosticky-mute').getAttribute('id') === 'mute'
                  ? document.querySelector('.audiosticky-mute').removeAttribute('id')
                  : document.querySelector('.audiosticky-mute').setAttribute('id', 'mute')
              }
            ></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudioPlayerSticky
