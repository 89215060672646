import React from 'react'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ReactDOMServer from 'react-dom/server'
import he from 'he'
import { sContext } from '../../context'
import TextStyles from './TextStyles'
import TopNoImage from './Article/TopNoImage'
import TopHeroImageVideo from './Article/TopHeroImageVideo'
import TopPortrait from './Article/TopPortrait'
import TopShare from './Article/TopShare'
import BodyText from './Article/BodyText'
import BodyTextFullBleed from './Article/BodyTextFullBleed'
import AssetSmall from './Article/AssetSmall'
import AssetMedium from './Article/AssetMedium'
import AssetLarge from './Article/AssetLarge'
import PullQuote from './Article/PullQuote'
import AssetDiptych from './Article/AssetDiptych'
import AssetBigSmall from './Article/AssetBigSmall'
import AssetSmallBig from './Article/AssetSmallBig'
import AudioPlayerEmbedded from './Article/AudioPlayerEmbedded'
import AudioPlaylist from './Article/AudioPlaylist'
import AirCanadaLinks from './CityGuide/AirCanadaLinks'
import TravelEssentials from './Article/TravelEssentials'
import ReactMarkdown from '../../lib/ReactMarkdown'
import LeadInText from './Article/LeadInText';
import Subheadline from './Article/Subheadline';
import AudioPlayerSticky from './Article/AudioPlayerSticky';
import Byline from './Article/Byline';
import IssuesGrid from '../components/Article/IssuesGrid'
import IssueHeadline from '../components/Article/IssueHeadline'
import { LinkRenderer } from '../../lib/utils'
import CBNRTopHeroImageVideo from '../components/CBNRTop10/CBNRTopHeroImageVideo'
import Top10 from '../components/CBNRTop10/Top10'
import Spoonsor from '../components/CBNRTop10/Sponsor'
import Top404 from '../components/Article/Top404'
import MagazineStoryFeed from './MagazineStoryFeed'
import EntriesSection from './EntriesSection'
import Ads from './Ads'
import SponsorModule from './sponsor/SponsorModule'
import FullWidthFade from './FullWidthFade'

const customStyleBuild = (style, chunk, customStyles) => {
  if(chunk.charAt(0) === '<') { return chunk }
  chunk = he.decode(chunk)

  for (let i = 0; i < customStyles.length; i++) {
    const pieceRes = customStylePiece({
      ...customStyles[i],
      chunk,
      style
    })

    if(pieceRes) {
      chunk = pieceRes.chunk
      style = pieceRes.style
      break
    }
  }

  chunk = chunk.join('')

  let hasCustomStyle = false
  for (let i = 0; i < customStyles.length; i++) {
    const customStyle = customStyles[i];
    if(chunk.split(customStyle.filter).length > 1) {
      hasCustomStyle = true
      break
    }
  }

  return hasCustomStyle ?
    customStyleBuild(style, chunk, customStyles) : `<mark ${style}"${chunk}`
}

const customStylePiece = ({
  chunk,
  style,
  filter,
  output
}) => {
  if(chunk.split(filter).length > 1) {
    chunk = chunk.split(filter)
    for (let i = 0; i < chunk.length; i++) {
      let subChunk = chunk[i];
      if(
        subChunk === undefined ||
        subChunk === ' ' ||
        (subChunk.charAt(0) !== `"` && subChunk.charAt(0) !== `'`)
      ) {
        continue
      }
      const quote = subChunk.charAt(0)
      subChunk.substring(1)
      subChunk = subChunk.split(quote)
      style += output.replace('?', subChunk[1])
      subChunk.splice(0, 2)
      chunk[i] = subChunk.join(quote)
      return { chunk, style }
    }
  }
}

const htmlFormatCustom = (html, customStyles) => {
  let formatedHtml = ''
  let splitHtml = html.split('&lt;mark ')
  if(splitHtml.length === 1) { return html }

  for (let i = 0; i < splitHtml.length; i++) {
    const item = splitHtml[i];
    const chunk = customStyleBuild('style="', item, customStyles)

    formatedHtml += chunk
  }

  return formatedHtml
}

const emptyModule = ({path, module}) => {
  return () => {
    console.error('Module', module, 'not found on path', path)
    return <div></div>
  }
}

class RichTextRenderer extends React.PureComponent {
  isMobileDevice = this.context.isMobileDevice
  currentLocale = this.context.currentLocale
  bylineIndex = null
  hasByline = !!this.props.pages.byline
  adsSeen = 0
  adIndex = 0
  refIndex = -1
  entriesUsedId = [this.props.pageId]
  isShortForm = false

  updateEntriesUsedId = ({ sys: { id }}) => {
    this.entriesUsedId.push(id)
  }

  entryRenderer({ data }) {
    const {
      currentLocale,
      props: {
        hideStickyAds,
        isCBNRArticle,
        isEmbededZoom,
        pages,
        latestIds,
        pages: {
          tags,
          metaDescription,
          category,
          articleType
        },
        pageId,
        categoryInformation
      }
    } = this

    let adShown = false
    let byLineDone = false
    let nonBodyTextAfterByLine = false

    let {
      fields: {
        title,
        subtitle,
        type,
        tag,
        subSections,
        images,
        image,
        text,
        videoAutoplay,
        credit,
        mapColour,
        mapMarkers,
        mapZoom,
        mapCenter,
        aggregatorTag,
        airCanadaLinkDest,
        video_cover_image,
        airCanadaLinkTc,
        leftImage,
        rightImage,
        audioTracks,
        numberOfRooms,
        isThereASpa,
        arePetsAllowed,
        isThereFreeWifi,
        isThereAPool,
        doesItContainARestaurant,
        isThereAGym,
        referencedMapSection,
        imageTitle,
        columnWidth: pColumnWidth,
        cbnrsNumber,
        cbnrsSponsorImage,
        annotationStartNumber,
        alt,
        url,
        address,
        pageTopTitle,
        fullBleedImage,
        menu_title,
        menuTitle,
        overrideBanner,
        inArticleAsset,
        landscapeMobileAsset,
        firstLevelText,
        firstLevelImage,
        secondLevelText,
        secondLevelImage,
        thirdLevelText,
        thirdLevelImage,
        topSponsorSection,
        rightLink,
        leftLink,
        filterBy,
        entries,
        button,
        hasBottomHr
      } = {},
      sys: {
        id: moduleId
      } = {}
    } = data.target || {
      fields: {},
      sys: {}
    }

    let ref = null

    let module = data.target?.fields?.type ?? null

    if (`${tag} ${articleType} ${tags && tags.join(',')} ${aggregatorTag}`.toLocaleLowerCase().indexOf('short read') >= 0) {
      this.isShortForm = true
    }

    // FRONTEND: What is this for?
    if (!adShown && module) {
      if (byLineDone) {
        if (
          !nonBodyTextAfterByLine &&
          module.indexOf('article-body-text') === -1
        ) {
          // Non body text done now that by line is done
          nonBodyTextAfterByLine = true
          
        } else if (
          nonBodyTextAfterByLine &&
          module.indexOf('article-body-text') !== -1
        ) {
          adShown = true
        }

      } else {
        if (
          !byLineDone &&
          module.indexOf('article-body-text') !== -1 &&
          subSections
        ) {
          byLineDone = true
        }
      }
    }

    let columnWidth

    if(pColumnWidth) {
      columnWidth = pColumnWidth
    } else if(
      this.hasByline &&
      !subSections &&
      (
        module === 'article-body-text' ||
        module === 'article-body-text-drop-cap' ||
        module === 'article-body-text-list' ||
        module === 'article-body-text-annotation-list'
      )
    ) {
      this.hasByline = true
    }

    const fadeProps = {
      module: module,
      key: `module-${moduleId}`
    }

    switch (module) {
      case 'issues-grid':
        return (
          <FullWidthFade {...fadeProps}>
            <IssuesGrid />
          </FullWidthFade>
        )

      case 'escapes-section-title':
        if(menu_title) {
          this.refIndex++
          ref = this.props.refs[this.refIndex]
        }

        return (
          <FullWidthFade
            {...fadeProps}
            ref={ref}
            menuTitle={menu_title} >
            <div className="cArticleSectionTitle row">
              <div className="cArticleSectionTitle-inner">
                <ReactMarkdown
                  renderers={{ link: LinkRenderer }}
                  source={text}
                  escapeHtml={false} />
              </div>
            </div>
          </FullWidthFade>
        )

      case 'issue-headline':
        return (
          <FullWidthFade
            {...fadeProps}
            fade={false} >
            <IssueHeadline
              text={text}
              published_at={subSections ? subSections[0].fields.published_at : null}
              categoryInformation={categoryInformation} />
          </FullWidthFade>
        )
        
      case 'article-top-no-image':
        if(subSections && subSections[0].fields) {
          return (
            <FullWidthFade {...fadeProps}>
              <TopNoImage
                type={
                  subSections &&
                  subSections.length &&
                  subSections[0] &&
                  subSections[0].fields ? subSections[0].fields.type : null
                }
                title={subSections[0].fields.title}
                subtitle={subSections[0].fields.subtitle}
                sponsored_by={subSections[0].fields.sponsored_by}
                metaDescription={metaDescription} />
            </FullWidthFade>
          )
        } else {
          return <></>
        }

      case 'article-top-hero-image-video':
        if(isCBNRArticle) {
          return (
            <FullWidthFade
              {...fadeProps}
              fade={false} >
              <CBNRTopHeroImageVideo
                text={text}
                image={image}
                title={title}
                subtitle={credit}
                sponsorImage={images}
                number={annotationStartNumber} />
            </FullWidthFade>
          )

        } else {

          let imageObject = {
            image,
            inArticleAsset,
            landscapeMobileAsset
          }

          if(subSections && subSections.length && subSections[0] && subSections[0].fields) {
            imageObject.banner = subSections[0].fields.banner
          }

          return (
            <FullWidthFade
              {...fadeProps}
              fade={false} >
              <TopHeroImageVideo
                images={imageObject}
                text={
                  subSections &&
                  subSections.length &&
                  subSections[0] &&
                  subSections[0].fields ? text : null
                }
                type={
                  subSections &&
                  subSections.length &&
                  subSections[0] &&
                  subSections[0].fields ? subSections[0].fields.type : null
                }
                title={
                  subSections &&
                  subSections.length &&
                  subSections[0] &&
                  subSections[0].fields ? subSections[0].fields.title : title
                }
                subtitle={
                  subSections &&
                  subSections.length &&
                  subSections[0] &&
                  subSections[0].fields ? subSections[0].fields.subtitle : credit
                }
                sponsorImage={image}
                sponsored_by={
                  subSections &&
                  subSections.length &&
                  subSections[0] &&
                  subSections[0].fields ? subSections[0].fields.sponsored_by : null
                }
                metaDescription={metaDescription} />
            </FullWidthFade>
          )
        }

      case 'article-top-portrait':
        if(subSections && subSections[0].fields) {
          return (
            <FullWidthFade
              {...fadeProps}
              fade={false} >
              <TopPortrait
                text={text}
                images={{
                  banner: subSections[0].fields.banner
                }}
                type={subSections[0].fields.type}
                title={subSections[0].fields.title}
                subtitle={subSections[0].fields.subtitle}
                sponsorImage={image}
                sponsored_by={subSections[0].fields.sponsored_by}
                metaDescription={metaDescription} />
            </FullWidthFade>
          )
        } else {
          return <></>
        }

      case 'article-body-text':
        if (columnWidth && columnWidth === 'full-bleed') {
          return (
            <FullWidthFade
              {...fadeProps}
              textType={'full-bleed'}
              cascade={true}
              fade={false}
              isHidePage={pages.hidePage} >
              <BodyTextFullBleed text={text} />
            </FullWidthFade>
          )

        } else {
          return (
            <FullWidthFade
              {...fadeProps}
              cascade={true}
              fade={false} >
              <BodyText
                hideStickyAds={hideStickyAds}
                subSections={subSections}
                text={text}
                type="body-text" />
            </FullWidthFade>
          )
        }

      case 'article-body-text-drop-cap':
        return (
          <FullWidthFade
            {...fadeProps}
            fade={false}
            cascade={true} >
            <BodyText
              hideStickyAds={hideStickyAds}
              subSections={subSections}
              text={text}
              type="drop-cap" />
          </FullWidthFade>
        )

      case 'article-body-text-list':
        return (
          <FullWidthFade
            {...fadeProps}
            cascade={true} >
            <BodyText
              hideStickyAds={hideStickyAds}
              subSections={subSections}
              text={text}
              type="list" />
          </FullWidthFade>
        )

      case 'article-body-text-annotation-list':
        return (
          <FullWidthFade
            {...fadeProps}
            cascade={true} >
            <BodyText
              hideStickyAds={hideStickyAds}
              subSections={subSections}
              text={text}
              type="annotation-list" />
          </FullWidthFade>
        )

      case 'article-asset-small':
        return (
          <FullWidthFade
            {...fadeProps}
            addedClass='asset-wrapper' >
            <AssetSmall
              text={text}
              image={image}
              credit={credit}
              videoAutoplay={videoAutoplay}
              coverImage={video_cover_image} />
          </FullWidthFade>
        )

      case 'article-asset-medium':
        return (
          <FullWidthFade
            {...fadeProps}
            addedClass='asset-wrapper' >
            <AssetMedium
              text={text}
              image={image}
              credit={credit}
              videoAutoplay={videoAutoplay}
              coverImage={video_cover_image} />
          </FullWidthFade>
        )

      case 'article-asset-large':
        return (
          <FullWidthFade
            {...fadeProps}
            addedClass='asset-wrapper' >
            <AssetLarge
              text={text}
              image={image}
              credit={credit}
              videoAutoplay={videoAutoplay}
              coverImage={video_cover_image} />
          </FullWidthFade>
        )

      case 'article-asset-diptych':
        return (
          <FullWidthFade
            {...fadeProps}
            addedClass='asset-wrapper' >
            <AssetDiptych
              images={images}
              text={text}
              image={image}
              credit={credit}
              videoAutoplay={videoAutoplay}
              coverImage={video_cover_image} />
          </FullWidthFade>
        )

      case 'article-asset-big-small':
        return (
          <FullWidthFade
            {...fadeProps}
            addedClass='asset-wrapper' >
            <AssetBigSmall
              images={images}
              text={text}
              image={image}
              credit={credit}
              videoAutoplay={videoAutoplay}
              coverImage={video_cover_image} />
          </FullWidthFade>
        )

      case 'article-asset-small-big':
        return (
          <FullWidthFade
            {...fadeProps}
            addedClass='asset-wrapper' >
            <AssetSmallBig
              images={images}
              text={text}
              image={image}
              credit={credit}
              videoAutoplay={videoAutoplay}
              coverImage={video_cover_image} />
          </FullWidthFade>
        )

      case 'article-pull-quote-large':
        return (
          <FullWidthFade {...fadeProps}>
            <PullQuote
              text={text}
              attribution={credit}
              size="large" />
          </FullWidthFade>
        )

      case 'article-pull-quote-small':
        return (
          <FullWidthFade {...fadeProps}>
            <PullQuote
              text={text}
              attribution={credit}
              size="small" />
          </FullWidthFade>
        )

      case 'article-lead-in-text-large':
        return (
          <FullWidthFade {...fadeProps}>
            <LeadInText
              text={text}
              size="large" />
          </FullWidthFade>
        )

      case 'article-lead-in-text-small':
        return (
          <FullWidthFade {...fadeProps}>
            <LeadInText
              text={text}
              size="small" />
          </FullWidthFade>
        )

      case 'article-subheadline':
        return (
          <FullWidthFade {...fadeProps}>
            <Subheadline text={text} />
          </FullWidthFade>
        )

      case 'article-audio-player-embedded':
        return (
          <FullWidthFade {...fadeProps}>
            <AudioPlayerEmbedded subSections={subSections} />
          </FullWidthFade>
        )

      case 'article-audio-player-sticky':
        return (
          <FullWidthFade
            {...fadeProps}
            fade={false} >
            <AudioPlayerSticky />
          </FullWidthFade>
        )

      case 'article-audio-playlist':
        return (
          <FullWidthFade {...fadeProps}>
            <AudioPlaylist subSections={subSections} />
          </FullWidthFade>
        )

      case 'article-hotel-map-data':
        const HotelMapData = this.props.moduleOverrides && this.props.moduleOverrides.HotelMapData ? this.props.moduleOverrides.HotelMapData : emptyModule({module: 'HotelMapData', path: pages.path})

        return (
          <FullWidthFade {...fadeProps}>
            <HotelMapData
              text={text}
              credit={credit}
              aggregatorTag={aggregatorTag}
              subSections={subSections} />
          </FullWidthFade>
        )

      case 'article-one-entry':
        const OneEntry = this.props.moduleOverrides && this.props.moduleOverrides.OneEntry ? this.props.moduleOverrides.OneEntry : emptyModule({module: 'OneEntry', path: pages.path})
        return (
          <FullWidthFade {...fadeProps}>
            <OneEntry
              text={text}
              credit={credit}
              subSections={subSections} />
          </FullWidthFade>
        )

      //Borrowed from CityGuide Modules!
      case 'cityguide-air-canada':

        return [
          category && (
            <FullWidthFade
              module='BottomRelatedContent'
              key={`module-${moduleId}-1`} >
                <EntriesSection
                  isArticle
                  category={category}
                  entriesUsedId={this.entriesUsedId}
                  section={{
                    intro: currentLocale === 'en' ?
                      'You Might Also Like' : 'Vous aimerez aussi',
                    filterBy: 'category',
                    type: 'medium-block'
                  }} />
            </FullWidthFade>
          ),
          (
            <FullWidthFade
              module={module}
              key={`module-${moduleId}-2`} >
              <AirCanadaLinks
                text={text}
                dest={airCanadaLinkDest}
                tc={airCanadaLinkTc} />
            </FullWidthFade>
          )
        ]

      case 'article-travel-essentials':
        return (
          <FullWidthFade {...fadeProps}>
            <TravelEssentials
              subSections={subSections}
              text={text} />
          </FullWidthFade>
        )

      default:
        module = data?.target?.sys?.contentType?.sys.id ?? null
        fadeProps.module = module

        let moduleProps
        let addedAttr

        switch (module) {
          case 'textStyles':
            return <TextStyles
              module={module}
              type={type}
              menuTitle={menuTitle} />

          case 'articleAssetSingle':
            addedAttr = {
              addedClass: 'asset-wrapper',
              ...fadeProps
            }

            moduleProps = {
              text,
              image,
              credit,
              videoAutoplay,
              coverImage: video_cover_image
            }
    
            if(type === 'small') {
              return (
                <FullWidthFade {...addedAttr}>
                  <AssetSmall {...moduleProps} />
                </FullWidthFade>
              )

            } else if(type === 'medium') {
              return (
                <FullWidthFade {...addedAttr}>
                  <AssetMedium {...moduleProps} />
                </FullWidthFade>
              )

            } else if(type === 'large') {
              return (
                <FullWidthFade {...addedAttr}>
                  <AssetLarge {...moduleProps} />
                </FullWidthFade>
              )

            } else {
              return null
            }

          case 'articleAssetMultiple':
            addedAttr = {
              addedClass: 'asset-wrapper',
              ...fadeProps
            }

            moduleProps = {
              text,
              credit,
              videoAutoplay,
              coverImage: video_cover_image
            }

            if(type === 'diptych') {
              return (
                <FullWidthFade {...addedAttr}>
                  <AssetDiptych
                    {...moduleProps}
                    firstImage={leftImage}
                    secondImage={rightImage}
                    rightLink={rightLink}
                    leftLink={leftLink} />
                </FullWidthFade>
              )

            } else if(type === 'big-small') {
              return (
                <FullWidthFade {...addedAttr}>
                  <AssetBigSmall
                    {...moduleProps}
                    image={leftImage}
                    images={[rightImage]} />
                </FullWidthFade>
              )

            } else if(type === 'small-big') {
              return (
                <FullWidthFade {...addedAttr}>
                  <AssetSmallBig
                    {...moduleProps}
                    image={leftImage}
                    images={[rightImage]} />
                </FullWidthFade>
              )

            } else {
              return null
            }

          case 'ArticleTop':
            moduleProps = {
              images: {
                image,
                overrideBanner,
                inArticleAsset
              },
              type: this.props.pages.articleType,
              title: imageTitle,
              subtitle,
              metaDescription
            }

            let topSponsor = {}
            if(topSponsorSection) {
              const { fields: sponsorSection } = topSponsorSection

              topSponsor = {
                firstLevelText: sponsorSection.firstLevelText,
                secondLevelText: sponsorSection.secondLevelText,
                thirdLevelText: sponsorSection.thirdLevelText,
                firstLevelImage: sponsorSection.firstLevelImage,
                secondLevelImage: sponsorSection.secondLevelImage,
                thirdLevelImage: sponsorSection.thirdLevelImage
              }
            }

            if(type === 'portrait') {
              return (
                <FullWidthFade
                  {...fadeProps}
                  fade={false} >
                  <TopPortrait {...moduleProps}/>
                  {topSponsor.firstLevelText && topSponsor.firstLevelImage && (
                    <SponsorModule sponsorData={topSponsor} />
                  )}
                </FullWidthFade>
              )

            } else if(type === 'image-video') {
              moduleProps.images.landscapeMobileAsset = landscapeMobileAsset

              if(isCBNRArticle) {
                return (
                  <FullWidthFade
                    {...fadeProps}
                    fade={false} >
                    <CBNRTopHeroImageVideo
                      {...moduleProps}
                      sponsorImage={cbnrsSponsorImage}
                      number={cbnrsNumber} />
                  </FullWidthFade>
                )

              } else {
                return (
                  <FullWidthFade
                    {...fadeProps}
                    fade={false} >
                    <TopHeroImageVideo
                      {...moduleProps}
                      isEmbededZoom={isEmbededZoom} />
                    {topSponsor.firstLevelText && topSponsor.firstLevelImage && (
                    <SponsorModule sponsorData={topSponsor} />
                    )}
                  </FullWidthFade>
                )
              }

            } else {
              return null
            }

          case 'pullQuote':
            moduleProps = {
              text,
              attribution: credit,
              size: type
            }

            return (
              <FullWidthFade {...fadeProps}>
                <PullQuote {...moduleProps} />
              </FullWidthFade>
            )

          case 'articleAudio':
            moduleProps = {
              subSections: audioTracks
            }

            if(type === 'player-embedded') {
              return (
                <FullWidthFade {...fadeProps}>
                  <AudioPlayerEmbedded {...moduleProps} />
                </FullWidthFade>
              )

            } else if(type === 'playlist') {
              return (
                <FullWidthFade {...fadeProps}>
                  <AudioPlaylist {...moduleProps} />
                </FullWidthFade>
              )

            } else {
              return null
            }

          case 'multiplePagesAirCanada':
            return [
              pages.category?.fields.path === 'magazine' && (
                <FullWidthFade
                  module='MagazineStoryFeed'
                  key={`module-${moduleId}-1`} >
                  <MagazineStoryFeed
                    pageId={pageId}
                    entriesId={latestIds} />
                </FullWidthFade>
              ),
              category && (
                <FullWidthFade
                  module='BottomRelatedContent'
                  key={`module-${moduleId}-2`} >
                  <EntriesSection
                    isArticle
                    category={category}
                    entriesUsedId={this.entriesUsedId}
                    section={{
                      intro: currentLocale === 'en' ?
                        'You Might Also Like' : 'Vous aimerez aussi',
                      filterBy: 'category',
                      type: 'medium-block'
                    }} />
                </FullWidthFade>
              ),
              (
                <FullWidthFade
                  module={module}
                  key={`module-${moduleId}-3`} >
                  <AirCanadaLinks
                    text={text}
                    dest={airCanadaLinkDest}
                    tc={airCanadaLinkTc} />
                </FullWidthFade>
              )
            ]

          case 'articleArticleTopNoImage':
            return (
              <FullWidthFade {...fadeProps}>
                <TopNoImage
                  type={this.props.pages.articleType}
                  title={imageTitle}
                  subtitle={subtitle}
                  metaDescription={metaDescription} />
              </FullWidthFade>
            )

          case 'articleSubheadline':
            return (
              <FullWidthFade {...fadeProps}>
                <Subheadline text={text} />
              </FullWidthFade>
            )

          case 'articleAudioPlayerSticky':
            return (
              <FullWidthFade
                {...fadeProps}
                fade={false} >
                <AudioPlayerSticky />
              </FullWidthFade>
            )

          case 'articleArticleHotelMapData':

            const details = [
              `rooms=${numberOfRooms}`,
            ]

            if(isThereASpa === 'Yes') {
              details.push('spa')
            }

            if(arePetsAllowed === 'Yes') {
              details.push('pets allowed')
            }

            if(isThereFreeWifi === 'Yes') {
              details.push('free wifi')
            }

            if(isThereAPool === 'Yes') {
              details.push('pool')
            }

            if(doesItContainARestaurant === 'Yes') {
              details.push('restaurant')
            }

            if(isThereAGym === 'Yes') {
              details.push('gym')
            }
            const HotelMapData = this.props.moduleOverrides && this.props.moduleOverrides.HotelMapData ? this.props.moduleOverrides.HotelMapData : emptyModule({module: 'HotelMapData', path: pages.path})
            return (
              <FullWidthFade
                module={module} >
                <HotelMapData
                  text={text}
                  credit={credit}
                  aggregatorTag={details}
                  subSections={[referencedMapSection]} />
              </FullWidthFade>
            )

          case 'articleTravelEssentials':
            return (
              <FullWidthFade
                module={module} >
                <TravelEssentials
                  subSections={subSections}
                  text={text} />
              </FullWidthFade>
            )

          case 'articleEscapesSectionTitle':
            if(menu_title) {
              this.refIndex++
              ref = this.props.refs[this.refIndex]
            }

            return (
              <FullWidthFade
                {...fadeProps}
                ref={ref}
                menuTitle={menu_title} >
                <div className="cArticleSectionTitle row">
                  <div className="cArticleSectionTitle-inner">
                    <ReactMarkdown
                      renderers={{ link: LinkRenderer }}
                      source={text} />
                  </div>
                </div>
              </FullWidthFade>
            )

          case 'articleIssuesGrid':
            return (
              <FullWidthFade
                module={module} >
                <IssuesGrid />
              </FullWidthFade>
            )

          case 'EntriesSection':
            return (
              <FullWidthFade
                {...fadeProps}
                fade={false} >
                <EntriesSection
                  isArticle
                  section={{
                    type,
                    filterBy,
                    entries,
                    button,
                    hasBottomHr
                  }}
                  updateEntriesUsedId={this.updateEntriesUsedId}
                  entriesUsedId={this.entriesUsedId} />
              </FullWidthFade>
            )

          case 'articleIssueHeadline':
            return (
              <FullWidthFade
                {...fadeProps}
                fade={false} >
                <IssueHeadline
                  text={text}
                  published_at={pages.published_at}
                  categoryInformation={categoryInformation}
                   />
              </FullWidthFade>
            )

          case 'mapSection':
            const MapModule = this.props.moduleOverrides && this.props.moduleOverrides.MapModule ? this.props.moduleOverrides.MapModule : emptyModule({module: 'MapModule', path: pages.path})
            return (
              <FullWidthFade {...fadeProps}>
                <div className="relative cMapContainer">
                  <MapModule
                    mapColour={mapColour}
                    mapMarkers={mapMarkers}
                    mapZoom={mapZoom}
                    mapCenter={mapCenter}
                    address={address}
                    isCBNRArticle={isCBNRArticle} />
                </div>
              </FullWidthFade>
            )

          /** This is an Asset Module used for the Zoom Webinar Registration Page */
          case 'link':
            if(isEmbededZoom) {
              return (
                <FullWidthFade {...fadeProps}>
                  <a
                    className='zoom-registration'
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer" >
                    {alt}
                  </a>
                </FullWidthFade>
              )
            } else {
              return (<></>)
            }

          case 'TopSocialShare':
            return (
              <FullWidthFade {...fadeProps}>
                <TopShare pages={pages} />
              </FullWidthFade>
            )
          
          case 'Sponsor':
            const sponsorProps = {
              firstLevelText,
              secondLevelText,
              thirdLevelText,
              firstLevelImage,
              secondLevelImage,
              thirdLevelImage
            }

            return (
              <FullWidthFade {...fadeProps}>
                <SponsorModule sponsorData={sponsorProps}/>
              </FullWidthFade>
            )

          case 'module404Top':
            return (
              <FullWidthFade
                {...fadeProps}
                fade={false} >
                <Top404
                  title={pageTopTitle}
                  subtitle={subtitle}
                  image={fullBleedImage} />
              </FullWidthFade> 
            )

          default:
            if (module && module !== 'pageSection') {
              console.error(`Unknown module --> ${module} <-- found on --> ${pages.path} <--`)
            }
            return null
        }
    }
  }

  options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: this.entryRenderer.bind(this),
      [INLINES.EMBEDDED_ENTRY]: this.entryRenderer.bind(this),
    },
    renderText: text => {
      return text.includes('iframe') ?
        text : `${text}`.replace(/(?!\")(\-)(?![^<]*?\")/g,'\u2011')
    }
  }

  htmlFormatAnchor = (html) => {
    let formatedHtml = html
    formatedHtml = formatedHtml.split('<a href')

    if(formatedHtml.length > 1) {
      for (let i = 0; i < formatedHtml.length; i++) {
        const item = formatedHtml[i];
        if(item.charAt(0) === '=') {
          if(
            item.includes('enroute.aircanada.com') ||
            item.includes('213a.studio') ||
            item.includes('localhost')
          ) {
            formatedHtml[i] = `<a href${item}`
            
          } else {
            formatedHtml[i] = `<a target="_blank" rel="noopener noreferrer" href${item}`
          }
        }
      }
    }

    return formatedHtml.join('')
  }

  render() {
    const {
      options,
      htmlFormatAnchor,

      props: {
        pages,
        content,
        isCBNRArticle
      }
    } = this

    const wrapperElement = ({
      fragment = false,
      nodes,
      node
    }, index) => {
      if (fragment) {
        return (
          <>
            {nodes}
          </>
        )
      }

      const textType = node && node.props.type
      if(!textType) { return }

      const {
        module,
        menuTitle
      } = node.props

      let html = ReactDOMServer.renderToString(nodes)

      html = htmlFormatAnchor(html)

      /** The ? in the output property value gets substituted with custom style value */
      const customStyles = [
        {
          filter: 'color=',
          output: `color: ?;`
        },
        {
          filter: 'size=',
          output: `font-size: ?px;`
        },
        {
          filter: 'font=',
          output: 'font-family: ?;'
        },
        {
          filter: 'underline=',
          output: `border-bottom: 1px solid ?;`
        }
      ]
      html = htmlFormatCustom(html, customStyles)

      html = html.replace(/<li><p><\/p><\/li>/g, '<li class="hide-list"></li>')

      html = he.decode(html);

      let ref = null

      if(menuTitle) {
        this.refIndex++
        ref = this.props.refs[this.refIndex]
      }

      const key = html.substring(0, 50);
      const fadeProps = {
        module,
        ref,
        key: `textStyles-${key}`
      }

      switch(textType) {
        case 'Body Text':
        case 'Drop Cap':
        case 'List':

          const { byline } = pages
          this.bylineIndex = this.bylineIndex || index

          let addedClass = 'body-style '
          if(textType === 'Body Text') {
            addedClass += ''
            html = html.replace(/<qa>/g, "<span class='body-text-qa'>")
            html = html.replace(/<\/qa>/g, '</span>')
            html = html.replace(
              /<anno>/g,
              "<span class='body-text-annotation-list superscript'>"
            )
            html = html.replace(/<\/anno>/g, '</span>')
          } else if(textType === 'Drop Cap') {
            addedClass += 'is-drop-cap'

          } else if(textType === 'List') {
            addedClass += 'body-text-list'
          }


          const isEmbededZoom = html.includes('zoom.us')
          return (
            <FullWidthFade
              {...fadeProps}
              fade={false}
              textType={textType}
              isEmbededZoom={isEmbededZoom} >
              <div
                id='bodytext'
                className={`
                  row
                  new-method
                  body-text
                  ${addedClass}
                  ${isEmbededZoom ? 'embeded-zoom' : ''}
                `} >
                <div className='body-text-content' >
                  <ReactMarkdown
                    source={html.split('<li><p><b></b></p></li>').join('<li class="empty"><p></p></li>')}
                    renderers={{
                      text: (node) => {
                        return `${node.value}`.replace(/-/g,'\u2011')
                      },
                    }}
                    escapeHtml={false} />
                </div>
                {this.bylineIndex === index && byline && byline.fields &&
                  <Byline
                    content={{
                      aggregatorTag: byline.fields.aggregatedTags,
                      text: byline.fields.date,
                      credit: byline.fields.authors,
                      smAuthor: byline.fields.smAuthor
                    }} />
                  }
              </div>
            </FullWidthFade>
          )

        case 'Annotation List':
          return (
            <FullWidthFade
              {...fadeProps}
              textType={textType} >
              <BodyText
                text={html}
                type="annotation-list" />
            </FullWidthFade>
          )

        case 'Sub Headline':
          return (
            <FullWidthFade
              {...fadeProps}
              textType={textType} >
              <div className="row subheadline-main">
                <div className="subheadline-text col-lg-10 offset-lg-1">
                  <ReactMarkdown
                    source={html}
                    renderers={{
                      text: (node) => {
                        return `${node.value}`.replace(/-/g,'\u2011')
                      }
                    }}
                    escapeHtml={false} />
                </div>
              </div>
            </FullWidthFade>
          )

        case 'Section Title':
          const reducedFonts = ['top-camping-canada']
          let isReduced
          for (let i = 0; i < reducedFonts.length; i++) {
            const reducedPath = reducedFonts[i];
            if(pages.path.includes(reducedPath)) {
              isReduced = true
              break
            }
          }

          return (
            <FullWidthFade
              {...fadeProps}
              textType={textType}
              menuTitle={menuTitle} >
              <div className="cArticleSectionTitle row">
                <div className={`
                  cArticleSectionTitle-inner
                  ${isReduced ? 'reduced-font' : ''}
                `}>
                  <ReactMarkdown
                    source={html}
                    renderers={{
                      text: (node) => {
                        return `${node.value}`.replace(/-/g,'\u2011')
                      }
                    }}
                    escapeHtml={false} />
                </div>
              </div>
            </FullWidthFade>
          )

        case 'Large Lead In Text':
        case 'Small Lead In Text':
          const isLarge = textType === 'Large Lead In Text'
          return (
            <FullWidthFade
              {...fadeProps}
              textType={textType} >
              <div className={`
                col-lg-6
                offset-lg-3
                leadintext-${isLarge ? 'large' : 'small'}
              `}>
                <ReactMarkdown
                  source={html}
                  renderers={{
                    text: (node) => {
                      return `${node.value}`.replace(/-/g,'\u2011')
                    }
                  }}
                  escapeHtml={false} />
              </div>
              <div className="leadintext-divider" />
            </FullWidthFade>
          )

        case 'Full Bleed':
          return (
            <FullWidthFade
              {...fadeProps}
              textType={textType}
              isHidePage={pages.hidePage} >
              <div className="body-text-full-bleed">
                <ReactMarkdown
                  source={html}
                  removeEmpty={pages.hidePage}
                  renderers={{
                    text: (node) => {
                      return `${node.value}`.replace(/-/g,'\u2011')
                    }
                  }}
                  escapeHtml={false} />
              </div>
            </FullWidthFade>
          )

        case 'Large Pull Quote':
        case 'Small Pull Quote':
          const pullType = textType.split(' ')[0].toLowerCase()
          return (
            <FullWidthFade
              {...fadeProps}
              textType={textType} >
              <PullQuote
                text={html}
                size={pullType} />
            </FullWidthFade>
          )
        default:
          return <h2>Unknown text style {textType}</h2>
      }
    }
    this.adsSeen = (content?.content?.length ? content?.content : []).filter(section => section?.data?.target?.sys?.contentType?.sys?.id === 'adSection').length
    const nodes = documentToReactComponents(content, options) || (<></>)
    const wrappers = [{
      fragment: false,
      nodes: []
    }]

    React.Children.forEach(nodes, (node) => {
      if(node && node.props && node.props.module && node.props.module === 'textStyles') {
        wrappers.push({
          fragment: false,
          node,
          nodes: []
        })

        return
      }

      if(node && node.props && node.props.module && !wrappers[wrappers.length - 1].fragment) {
        wrappers.push({
          fragment: true,
          nodes: []
        })

      } else if(!(node && node.props && node.props.module) && wrappers[wrappers.length - 1].fragment) {
        wrappers.push({
          fragment: false,
          nodes: []
        })
      }
      
      wrappers[wrappers.length - 1].nodes.push(node)

    })

    const legacySections = (this.props.sections || []).map((section) => {
      return this.entryRenderer({ data: { target: section } })
    })

    const {
      isShortForm,
      props: {
        isEmbededZoom,
        ignoreAds
      }
    } = this

    const showAd = !ignoreAds &&
                   !isEmbededZoom  &&
                   this.adsSeen === 0 &&
                   !isShortForm

    let showThirdAd = showAd && (
      (wrappers && wrappers.length > 12) ||
      (legacySections && legacySections.length > 12)
    )

    return (
      <>
        {wrappers.map((wrapper, i) => (
          <React.Fragment key={`module-wrapper-${i}`}>
            { showAd && i === 3 && (
              <FullWidthFade module='Leaderboard' >
                <Ads counter={2} />
              </FullWidthFade>
            )}
            { showThirdAd && i === 9 && (
              <FullWidthFade module='Leaderboard' >
                <Ads counter={3} />
              </FullWidthFade>
            )}
            {wrapperElement(wrapper, i)}
            {i === wrappers.length - 1 && (
              <FullWidthFade module='Leaderboard' >
                <Ads counter={4} />
              </FullWidthFade>
            )}
          </React.Fragment>
        ))}
        
        {legacySections.map((legacy, i) => (
          <React.Fragment key={`module-wrapper-1-${i}`}>
            {showAd &&
            i === 3 && (
              <FullWidthFade module='Leaderboard' >
                <Ads counter={2} />
              </FullWidthFade>
            )}
            {showThirdAd &&
            i === 9 && (
              <FullWidthFade module='Leaderboard' >
                <Ads counter={3} />
              </FullWidthFade>
            )}
            {legacy}
          </React.Fragment>
        ))}
        
        {isCBNRArticle && (
          <FullWidthFade
            module='Top10_&_Sponsor'
            fade={false} >
            <>
              <Top10 />
              <Spoonsor />
            </>
          </FullWidthFade>
        )}
      </>
    )
  }
}

RichTextRenderer.contextType = sContext

export default RichTextRenderer