import React from 'react'
import LazyLoadComponent from '../LazyLoadComponent'
import LazyLoadImage from '../LazyLoadImage'
import { LinkRenderer } from '../../../lib/utils'
import ReactMarkdown from '../../../lib/ReactMarkdown'

const AssetLarge = element => (
  <div className="row assetlarge-main">
    <div className="col-lg-12 assetlarge-image">
      {element.image.fields.file.contentType.split('/')[0] === 'video' ? (
        <div className="assetlarge-video-container">
          <LazyLoadComponent>
            <video
              controls={element.videoAutoplay ? false : 'controls'}
              className="assetlarge-video"
              autoPlay={element.videoAutoplay ? 'autoplay' : false}
              muted={element.videoAutoplay ? 'muted' : false}
              loop={element.videoAutoplay ? 'loop' : false}
              poster={
                element.coverImage &&
                element.coverImage.fields &&
                element.coverImage.fields.file &&
                element.coverImage.fields.file.url
              }
              cover={
                element.coverImage &&
                element.coverImage.fields &&
                element.coverImage.fields.file &&
                element.coverImage.fields.file.url
              } >
              <source
                src={element.image.fields.file.url}
                type={element.image.fields.file.contentType}
              />
            </video>
          </LazyLoadComponent>
        </div>
      ) : (
        <LazyLoadImage
          asset={element.image.fields}
          className="assetlarge-banner"
          sizes={[
            { viewport: 1600, width: 2500 },
            { viewport: 1400, width: 1600 },
            { viewport: 992, width: 1200 },
            { viewport: 0, width: 600 }
          ]}
        ></LazyLoadImage>
      )}
      {(element.text || element.credit) && (
        <div className="assetlarge-text col-lg-6 offset-lg-3">
          {element.text && (
            <React.Fragment>
              <ReactMarkdown
                source={element.text}
                escapeHtml={false}
                renderers={{ link: LinkRenderer, paragraph: 'span' }} />
            </React.Fragment>
          )}

          {element.credit && (
            <>
              &nbsp;&nbsp;&nbsp;
              <span className="assetlarge-credit">
                {element.credit}
              </span>
            </>
          )}
        </div>
      )}
    </div>
  </div>
)

export default AssetLarge
