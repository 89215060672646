import React from 'react'
import { getEntries } from '../../../lib/client'
import { sContext } from '../../../context'

class Top10 extends React.PureComponent {
  localePath = this.context.currentLocale

  state = {
    top10: [],
    locale: (this.localePath === '/en' && 'en-US') || 'fr'
  }

  componentDidMount() {
    getEntries({
      content_type: 'page',
      'fields.template': 'CBNR Top 10',
      order: 'fields.orderNumber'
    }, this.localePath).then(entries => {
      this.setState({
        top10: entries.items
      })
    })
  }

  render() {
    return (
      <div className={'row top10 black'}>
        <div className="col-lg-8 offset-lg-2">
          {this.state.top10.map(data => (
            <div
              className={'row-fluid'}
              key={data.fields.path} >
              <div className="col-lg-12 top10-image">
                <a href={'/' + this.localePath + '/top-10/' + data.fields.path}>
                  <div className="overlay"></div>
                  <p className="top10-image-container">
                    {data.fields.content.content[0].data.target.fields.image && (
                      <img
                        style={{ width: '100%' }}
                        src={
                          data.fields.content.content[0].data.target.fields.image.fields.file.url
                        }
                        alt=""
                      />
                    )}
                  </p>
                  <div className="top10-text">
                    <p className="top10-number">
                      {data.fields.content.content[0].data.target.fields.annotationStartNumber}
                    </p>
                    <p className="top10-title">
                      {data.fields.content.content[0].data.target.fields.title}
                    </p>
                    <p className="top10-credit">
                      {data.fields.content.content[0].data.target.fields.credit.toUpperCase()}
                    </p>
                  </div>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

Top10.contextType = sContext

export default Top10