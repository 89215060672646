import React, { useContext, useLayoutEffect, useState } from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'

import ReactMarkdown from '../../../lib/ReactMarkdown'
import { ArticleSchema } from '../../../lib/schemaMarkup'
import { getPortraitAsset } from '../../../lib/getAssets'

function adjustText(text) {
  if (text.length < 95) {
    return 'long-text'
  } else {
    return ''
  }
}

const TopPortrait = ({
  images,
  type,
  title,
  subtitle,
  sponsorImage,
  text,
  sponsored_by,
  metaDescription
}) => {
  const localePath = '/' + useContext(sContext).currentLocale
  const [asset, setAsset] = useState({})

  let sponsoredBy
  if(sponsored_by) {
    sponsoredBy = sponsored_by
  } else if(type?.includes('Air Canada enRoute')) {
    sponsoredBy = type
  }

  const sizes = [
    [0, 480],
    [320, 640],
    [640, 960],
    [960, 480],
    [1200, 640],
    [1600, 960],
  ].reverse()
  const windowSize =  typeof window !== 'undefined' ? window.innerWidth : 0
  const width = sizes.find(s => s[0] <= windowSize)[1]

  useLayoutEffect(() => {
    setAsset(getPortraitAsset(images))
  }, [images])


  return (
    <div className="row topportrait-main">
      <div className="col-lg-5 offset-1 topportrait-image">
        <LazyLoadBgImage
          asset={asset || {}}
          className="topportrait-imageInner"
          overrides={{ w: width, q: 70 }}
          usePadding={true} />
      </div>
      <div className="col-lg-6 topportrait-body">
        <div className="row topportrait-tag">
          {sponsoredBy ? (
            <p>{sponsoredBy}</p>
          ) : (
            <p>{type}</p>
          )}
        </div>
        <div className={`row topportrait-copy ${adjustText(title + subtitle)}`}>
          <h1>
            <ReactMarkdown
              escapeHtml={false}
              source={`${title}${subtitle ? ' — ' : ''}`} />  
          </h1>
          {subtitle && (
            <h2>
              <ReactMarkdown
                escapeHtml={false}
                source={subtitle} />          
            </h2>
          )}
        </div>
        {sponsorImage && (
          <div className="row topportrait-sponsor">
            <div className="col-lg-3 topportrait-sponsor-text">
              <ReactMarkdown
                source={text}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }} />
            </div>
            <LazyLoadBgImage
              asset={sponsorImage.fields}
              className="col-lg-3 topportrait-sponsor-image" />
          </div>
        )}
      </div>
      <ArticleSchema
        type={sponsoredBy || type}
        headline={title}
        about={metaDescription} />
    </div>
  )
}

export default TopPortrait
