import React from 'react'
import LazyLoadImage from '../LazyLoadImage'
import LazyLoadComponent from '../LazyLoadComponent'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const AssetSmall = element => (
  <div className="row assetsmall-main">
    <div className={`
      assetsmall-image
      ${!element.text ? 'col-lg-5': 'col-lg-5 offset-lg-1'}
      ${!element.text ? 'missing-caption': ''}
    `}>
      {element.image.fields.file.contentType.split('/')[0] === 'video' ? (
        <LazyLoadComponent>
          <video
            controls={element.videoAutoplay ? false : 'controls'}
            className="assetsmall-video"
            autoPlay={element.videoAutoplay ? 'autoplay' : false}
            muted={element.videoAutoplay ? 'muted' : false}
            loop={element.videoAutoplay ? 'loop' : false}
            poster={
              element.coverImage &&
              element.coverImage.fields &&
              element.coverImage.fields.file &&
              element.coverImage.fields.file.url
            } >
            <source
              src={element.image.fields.file.url}
              type={element.image.fields.file.contentType} />
          </video>
        </LazyLoadComponent>
      ) : (
        <LazyLoadImage
          asset={element.image.fields}
          className="assetsmall-banner"
          sizes={[
            { viewport: 1400, width: 800 },
            { viewport: 992, width: 400 },
            { viewport: 0, width: 600 }
          ]} />
      )}
      {element.credit && (
        <div className="assetsmall-credit">
          {element.credit}
        </div>
      )}
    </div>
    {element.text && (
      <div className="col-lg-6 assetsmall-body">
        <div className="assetsmall-text">
          <ReactMarkdown
            source={element.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer }} />
        </div>
      </div>
    )}
  </div>
)

export default AssetSmall
