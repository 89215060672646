import React from 'react'

class AudioPlayerEmbedded extends React.PureComponent {
  state = {
    play: false,
    currentTime: 0,
    duration: 0,
    barPercentage: 0
  }

  audioFile = typeof window !== 'undefined' ?
    new Audio(
      this.props.subSections[0].fields.audioFile.fields.file.url
    ) : {}
  playState = 'play'
  muteState = 'sound'

  toggleMute = () => {
    if (this.audioFile.muted) {
      this.audioFile.muted = false
      this.muteState = 'sound'
    } else {
      this.audioFile.muted = true
      this.muteState = 'mute'
    }
  }

  togglePlay = elm => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audioFile.play() : this.audioFile.pause()
    })

    if (!this.state.play) {
      this.playState = 'pause'
      var main = document.querySelector('.audiosticky-main')
      if (main && main.style.display === 'none')
        main.style.display = 'block';
      Array.from(document.querySelectorAll('#pause')).filter(el => el !== elm).forEach(e => e.click())
    } else {
      this.playState = 'play'
    }

    Array.from(document.querySelectorAll('.audiosticky-text span')).forEach(el => el.textContent = this.props.subSections[0].fields.title)
    Array.from(document.querySelectorAll('.audiosticky-text font')).forEach(el => el.textContent = this.props.subSections[0].fields.artist ? ' — ' + this.props.subSections[0].fields.artist : '')
    var sticky = document.querySelector('.audiosticky-play');
    var newId = sticky && sticky.getAttribute('id') === 'plays' ? 'pauses' : 'plays'
    Array.from(document.querySelectorAll('.audiosticky-play')).forEach(el => el.setAttribute('id', newId));
  }

  goToTime = data => {
    if(typeof window !== 'undefined') {
      var position = data.nativeEvent.offsetX
      var padding = parseInt(
        window.getComputedStyle(data.target).getPropertyValue('padding-left')
      )
      var width =
        parseInt(window.getComputedStyle(data.target).getPropertyValue('width')) -
        padding * 2
      if (position - padding > 0 && position - padding < width) {
        this.audioFile.currentTime =
          ((position - padding) / width) * this.audioFile.duration
      }
    }
  }

  componentDidMount() {
    this.audioFile.addEventListener('canplay', e => {
      this.setState({
        duration: e.target.duration
      })
    })

    this.audioFile.addEventListener('timeupdate', e => {
      this.setState({
        currentTime: e.target.currentTime,
        duration: e.target.duration,
        barPercentage: (e.target.currentTime / e.target.duration) * 100
      })
    })

    Array.from(document.querySelectorAll('.audiosticky-mute')).forEach(el => el.onClick = this.toggleMute.bind(this))
  }

  componentWillUnmount() {
    this.audioFile.removeEventListener('timeupdate', () => {})
  }

  render() {
    return (
      <div className="row audioembedded-main">
        <div className="col-lg-6 offset-3 audioembedded-box">
          <div className="row">
            <div
              className="col-lg-1 audioembedded-play"
              id={this.playState}
              onClick={() => this.togglePlay(this)}
            ></div>
            <div className="col-lg-11 audioembedded-controls">
              <div className="row audioembedded-title">
                {' '}
                <span>{this.props.subSections[0].fields.title}&nbsp;</span>
                {this.props.subSections[0].fields.artist &&
                   '— ' + this.props.subSections[0].fields.artist}
              </div>
              <div className="row audioembedded-control">
                <div className="col-lg-1 audioembedded-current">
                  {Math.floor(this.state.currentTime / 60) +
                    ':' +
                    ('0' + Math.floor(this.state.currentTime % 60)).slice(-2)}
                </div>
                <div className="col-lg-10 audioembedded-bar">
                  <div
                    className="audioembedded-bar-controller"
                    onClick={e => this.goToTime(e)}
                  ></div>
                  <div
                    className="audioembedded-bar-selected"
                    style={{ width: this.state.barPercentage + '%' }}
                  ></div>
                </div>
                <div className="col-lg-1 audioembedded-duration">
                  {Math.floor(this.state.duration / 60) +
                    ':' +
                    ('0' + Math.floor(this.state.duration % 60)).slice(-2)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default AudioPlayerEmbedded
