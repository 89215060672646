import React from 'react'
import { Helmet } from 'react-helmet'

const CBNRTopHeroImageVideo = element => (
  <div
    className="row tophero-main cbnr-top-10-hero"
    style={{
      backgroundImage:
        'radial-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0)), url(' +
        element.image.fields.file.url +
        ')'
    }}
  >
    <Helmet>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <meta property="title" content={element.title} />
      <meta property="description" content={element.subtitle} />

      <meta property="og:title" content={element.title} />
      <meta property="og:description" content={element.subtitle} />
      <meta property="og:image" content={element.image.fields.file.url} />
      <meta property="og:type" content="article" />

      <meta name="twitter:title" content={element.title} />
      <meta name="twitter:description" content={element.subtitle} />
      <meta name="twitter:image" content={element.image.fields.file.url} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
    {element.image.fields.file.contentType.split('/')[0] === 'video' && (
      <video autoPlay="true" muted="true" loop className="tophero-video">
        <source
          src={element.image.fields.file.url}
          type={element.image.fields.file.contentType}
        />
      </video>
    )}
    <div className="col-lg-12">
      <div className="row tophero-tag tophero-tag-cbnr">{element.number}</div>
      <div className="row tophero-title tophero-title-cbnr">
        {element.title}
      </div>
      <div className="row tophero-subtitle tophero-subtitle-cbnr">
        {element.subtitle && element.subtitle.toUpperCase()}
      </div>
      {element.sponsorImage && (
        <div className="tophero-sponsor tophero-sponsor-cbnr">
          <div className="tophero-sponsor-text tophero-sponsor-text-cbnr">
            {element.text}
          </div>
          <img src={element.sponsorImage[0].fields.file.url} alt="Sponsor Logo" />
        </div>
      )}
    </div>
  </div>
)

export default CBNRTopHeroImageVideo
