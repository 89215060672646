import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class Subheadline extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text
    }
  }

  render() {
    let response = null
    response = (
      <div className="subheadline-text col-lg-9 offset-lg-1">
        <ReactMarkdown
          source={this.state.text}
          escapeHtml={false}
          renderers={{ link: LinkRenderer }}
        />
      </div>
    )
    return (
      <div className="row subheadline-main" style={{ padding: '3% 0' }}>
        {response}
      </div>
    )
  }
}

export default Subheadline
