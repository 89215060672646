import React, { useContext, useLayoutEffect, useState } from 'react'
import LazyLoadBgImage from '../LazyLoadBgImage'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'
import { ArticleSchema } from '../../../lib/schemaMarkup'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import { getLandscapeAsset } from '../../../lib/getAssets'

const TopHeroImageVideo = ({
  type,
  title,
  subtitle,
  sponsorImage,
  text,
  sponsored_by,
  isEmbededZoom,
  metaDescription,
  images
}) => {
  const localePath = useContext(sContext).currentLocale
  const [asset, setAsset] = useState({})

  const sizes = [
    [0, 480],
    [320, 640],
    [640, 960],
    [960, 1280],
    [1200, 2048],
  ].reverse()
  const windowSize =  typeof window !== 'undefined' ? window.innerWidth : 0
  const width = sizes.find(s => s[0] <= windowSize)[1]

  let sponsoredBy
  if(sponsored_by) {
    sponsoredBy = sponsored_by
  } else if(type?.includes('Air Canada enRoute')) {
    sponsoredBy = type
  }

  useLayoutEffect(() => {
    setAsset(getLandscapeAsset({
      ...images,
      topType: 'image-video'
    }))
  }, [images])

  return (
    <LazyLoadBgImage
      className={`
        row
        tophero-main
        ${isEmbededZoom ? 'is-embeded-zoom-top' : ''}
      `}
      asset={asset || {}}
      overrides={{ w: width, q: 90 }}
      gradient="radial" >
      <div className="col-lg-12">
        <div className="row tophero-tag">
          {sponsoredBy ? (
            <p>
              {sponsoredBy}
            </p>
          ) : (
            <p>{type}</p>
          )}
        </div>
        <div className="row tophero-title">
          <div className="tophero-title-content">
            <h1>
              <ReactMarkdown
                escapeHtml={false}
                source={`${title}${subtitle ? ' — ' : ''}`} />
            </h1>
            
            {subtitle && (
              <h2>
                <ReactMarkdown
                  escapeHtml={false}
                  source={subtitle} />          
              </h2>
            )}
          </div>
        </div>
        {sponsorImage && (
          <div className="row tophero-sponsor">
            <div className="col-lg-2 tophero-sponsor-text">
              <ReactMarkdown
                source={text}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }} />
            </div>
            <LazyLoadBgImage
              asset={sponsorImage.fields}
              className="col-lg-2 tophero-sponsor-image" />
          </div>
        )}
      </div>
      <ArticleSchema
        type={sponsoredBy || type}
        headline={title}
        about={metaDescription} />
    </LazyLoadBgImage>
  )
}

export default TopHeroImageVideo