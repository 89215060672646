import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

class PullQuote extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      text: props.text,
      size: props.size,
      attribution: props.attribution
    }
  }
  render() {
    let response = null
    if (this.state.size === 'large') {
      response = (
        <div className="pullquote-large">
          <ReactMarkdown
            source={this.state.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer,
              text: (node) => {
                return `${node.value}`.replace(/-/g,'\u2011')
              } }}
          />
          <div className="pullquote-large-divider"></div>
          {this.state.attribution && (
            <p className="pullquote-attribution">
              {this.state.attribution}
            </p>
          )}
        </div>
      )
    } else if (this.state.size === 'small') {
      response = (
        <div className="pullquote-small col-lg-8 offset-lg-1">
          <ReactMarkdown
            source={this.state.text}
            escapeHtml={false}
            renderers={{ link: LinkRenderer,
              text: (node) => {
                return `${node.value}`.replace(/-/g,'\u2011')
              } }}
          />
          <p className="pullquote-attribution">{this.state.attribution}</p>
        </div>
      )
    }
    return (
      <div className="pullquote">
        {response}
      </div>
    )
  }
}

export default PullQuote
