import React, {useState, useEffect} from 'react'
import Article from './Article'
import {getCurrentPageData} from '../lib/client'
import Homepage from './Homepage'

const ArticlePreview = (props) => {
  const Fallback = props.fallback
  const [pageData, setPageData] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const data = await getCurrentPageData('page', typeof window !== 'undefined' ? window.location.search : '')
      setPageData(data)
      if (!data || !data.pageData || !data.pageData.items || !data.pageData.items.length) {
        if (typeof window !== 'undefined') {
          let currentLocale = localStorage.getItem('currentLocale') || 'en'
          localStorage.setItem('currentLocale', currentLocale)
          window.location = `/${currentLocale}/${404}`
        }
      }
    }
    fetchData()
  }, [])

  if(!pageData?.pageData?.items?.length) {
    return <></>
  }

  const [{
    fields,
    fields: {
      template
    }
  }] = pageData.pageData.items

  if(template === "Homepage") {
    const contents = fields.content.content.map(item => {
      if(item?.data?.target) {
        const {
          target,
          target: {
            fields: targetFields,
            sys,
            sys: { contentType: { sys: {
              id
            }
          }}}
        } = item.data
        
        let formatedValue
        if(id === 'homepageTextBlock') {
          formatedValue = {
            fields: targetFields.text,
            sys
          }
        }
  
        return formatedValue || target
      }
    })

    return <Homepage {...props} pages={{ content: contents }} />

  } else {
    return <Article {...props} pages={fields} />
  }
}

export default ArticlePreview
