import React, { useContext } from 'react'
import { sContext } from '../../../context'

const sponsors = [
  {
    url: 'https://greenhousejuice.com/',
    image: 'greenhouse_white.png'
  },
  {
    url: 'https://www.all-clad.com/',
    image: 'all-clad.svg'
  },
  {
    url: 'https://www.dufflet.com/',
    image: 'Dufflet_2017_wordmark_green.svg'
  },
  {
    url: 'https://www.woodfordreserve.com/',
    image: 'Woodford-Reserve.png'
  },
  {
    url: 'https://www.herradura.com/',
    image: 'Tequila-Herradura.png'
  },
  {
    url: 'https://www.titosvodka.com/',
    image: 'titos-handmade-vodka_white.png'
  },
  {
    url: 'https://www.stagsleap.com/',
    image: 'stags-leap_white.png'
  }
]

const SponsorItem = ({ url, image }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <img height="150" width="150" alt="" src={`/files/sponsors/${image}`} />
  </a>
)

const Sponsor = () => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div className="row sponsors">
      <div className="present-by">
        {localePath === 'en' ? 'PRESENTED BY' : 'Présenté par'}
      </div>
      <div className="sponsor-main">
        <SponsorItem
          url="https://www.americanexpress.com/"
          image="1200px-American_Express_logo__2018_.svg.png" />
      </div>
      <div className="sponsor-list">
        {sponsors.map(sponsor => {
          const {
            url,
            image
          } = sponsor

          return (
            <SponsorItem
              key={url}
              url={url}
              image={image} />
          )
        })}
      </div>
    </div>
  )
}

export default Sponsor
