import React from 'react'
import { LinkRenderer } from '../../../lib/utils'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const BodyTextFullBleed = ({ text: pText }) => {
  let text = pText.replace(/<qa>/g, "<span class='body-text-qa'>")
  text = text.replace(/<\/qa>/g, '</span>')
  text = text.replace(
    /<anno>/g,
    "<span class='body-text-annotation-list superscript'>"
  )
  text = text.replace(/<\/anno>/g, '</span>')

  return (
    <div className="body-text-full-bleed">
      <div className="col-lg-12">
        <ReactMarkdown
          source={text}
          escapeHtml={false}
          renderers={{ link: LinkRenderer,
            text: (node) => {
              return `${node.value}`.replace(/-/g,'\u2011')
            } }}
        />
      </div>
    </div>
  )
}

export default BodyTextFullBleed