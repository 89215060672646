import React, { useContext } from 'react'
import LazyLoadImage from '../LazyLoadImage'
import { LinkRenderer } from '../../../lib/utils'
import { sContext } from '../../../context'

import ReactMarkdown from '../../../lib/ReactMarkdown'

const TravelEssentials = element => {
  const localePath = useContext(sContext).currentLocale
  const locale = localePath=== '/fr' ? 'fr' : 'en-US'
  
  return (
    <div className={`row links-main ${element.color || ''}`}>
      <div className="col-lg-12">
        <div
          className="links-container travel-essentials-container"
          id={'links-container-' + element.id}
        >
          <div className="row links-line">
            <div className="col-lg-1 links-line-left"></div>
            <div className="col-lg-1 links-line-right"></div>
          </div>
          <div id="title" className="row links-title">
            {' '}
            {element.text}{' '}
          </div>
          {element.subSections &&
            element.subSections.map(data => (
              <div
                className={
                  'row links-guides ' + (data.fields.type ? data.fields.type : '')
                }
              >
                {(data.fields.image && [
                  <div className="col-lg-4 links-image">
                    <LazyLoadImage
                      asset={data.fields.image.fields}
                      className="links-image-file"
                      sizes={[{ viewport: 0, width: 400 }]}
                    ></LazyLoadImage>
                  </div>,
                  <div className="col-lg-6 links-text travel-essentials-title">
                    {data.fields.type && (
                      <h4 className="links-type">{data.fields.type}</h4>
                    )}
  
                    <ReactMarkdown renderers={{ link: LinkRenderer }} escapeHtml={false} source={data.fields.text} />
                  </div>
                ]) || (
                  <div className="col-lg-6 offset-4 links-text travel-essentials-title">
                    {data.fields.type && (
                      <h4 className="links-type">{data.fields.type}</h4>
                    )}
                    <ReactMarkdown renderers={{ link: LinkRenderer }} escapeHtml={false} source={data.fields.text} />
                  </div>
                )}
                <div className="col-lg-2 links-links">
                  {data.fields.websiteLink && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={data.fields.websiteLink}
                    >
                      {locale === 'en-US' ? 'Website' : 'Site Web'}
                    </a>
                  )}
                  {data.fields.directionsLink && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={data.fields.directionsLink}
                    >
                      {locale === 'en-US' ? 'Directions' : 'Pour S’orienter'}
                    </a>
                  )}
                  {data.fields.instagramLink && (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={data.fields.instagramLink}
                    >
                      Instagram
                    </a>
                  )}
                  {data.fields.links &&
                    data.fields.links.map((link, index) => (
                      <a
                        key={'links-' + index}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={link.fields.url}
                      >
                        {link.fields.text.split('|')[0]}
                      </a>
                    ))}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

export default TravelEssentials
