import React, { useContext } from 'react'
import { sContext } from '../../../context'
import ReactMarkdown from '../../../lib/ReactMarkdown'
import { ArticleSchema } from '../../../lib/schemaMarkup'

const TopNoImage = ({
  type,
  title,
  subtitle,
  sponsored_by,
  metaDescription
}) => {
  const localePath = '/' + useContext(sContext).currentLocale

  const isDigital =
    type === 'Digital Magazine' ||
    type === 'Magazine numérique' ||
    type === 'magazine numérique' ||
    type === 'Magazine Numérique'

  return (
    <div className={`row topnoimage-main ${isDigital ? 'no-text' : ''}`} >
      <div className="col-lg-12">
        <div className="row topnoimage-tag">
          {sponsored_by ? (
            <p>{sponsored_by}</p>
          ) : (
            <a href={localePath + '/category/?aggTag=' + type}>
              {type}
            </a>
          )}
        </div>
        {!isDigital && (
          <>
            <div className="topnoimage-copy">
              <div className="topnoimage-title">
                <h1>
                  <ReactMarkdown
                    escapeHtml={false}
                    source={`${title}${subtitle ? ' — ' : ''}`} />  
                </h1>

                {subtitle && (
                  <h2>
                    <ReactMarkdown
                      escapeHtml={false}
                      source={subtitle} />          
                  </h2>
                )}
              </div>
            </div>
            <div className="row topnoimage-lines">
              <div className="col-xs-6 topnoimage-lines-left" />
              <div className="col-xs-6 topnoimage-lines-right" />
            </div>
          </>
        )}
      </div>
      <ArticleSchema
        type={sponsored_by || type}
        headline={title}
        about={metaDescription} />
    </div>
  )
}

export default TopNoImage