import React, { useContext } from 'react'
import { sContext } from '../../../context'
import { BylineSchema } from '../../../lib/schemaMarkup'

const Byline = ({
  content: {
    aggregatorTag,
    text,
    credit = '',
    smAuthor
  }
}) => {
  const localePath = useContext(sContext).currentLocale

  return (
    <div
      key={'subsection-Byline'}
      className="body-text-sidebar"
    >
      {aggregatorTag && (
        <div className="body-text-tags">
          {aggregatorTag.map((tags, i) => {
            const tagLink = `/${localePath}/category/?aggTag=${tags}`
            return (i <
              aggregatorTag.length -
                1 && (
              <React.Fragment key={'tags-' + i} >
                <a
                  href={tagLink}
                  id="uline" >
                  {`${tags}`}
                </a>
                <span>
                  ,&nbsp;
                </span>
              </React.Fragment>
            )) ||
            (i >=
              aggregatorTag.length -
                1 && (
              <a
                key={'tags-' + i}
                href={tagLink}
                onClick={() => {
                  if(typeof document !== 'undefined') {
                    document.location.pathname = tagLink
                  }
                }}
                id="uline" >
                {tags}
              </a>
            ))
          })}
        </div>
      )}
      <div
        className={
          'body-text-bylines' +
          ((!aggregatorTag &&
            ' body-text-bylines-no-tags') ||
            '')
        }
      >
        {credit.split(' | ')[0]}
        {credit
          .split(' | ')
          .map((lines, i) => i > 0 && [<br key={'break-' + i} />, lines])}
      </div>
      <div className="body-text-timestamp">
        {text}
      </div>

      <BylineSchema
        date={text}
        author={smAuthor}
        aggTags={aggregatorTag} />
    </div>
  )
}

export default Byline