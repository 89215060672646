import React from 'react';
import Layout, { buildProps } from './index.js';
import Article from './main-components/Article';
import ArticlePreview from './main-components/ArticlePreview';

const Component = (props) => {
  const {
    navigation,
    footer,
    secondLangPath,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    paths,
    latestIds,
    location
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    secondLangPath,
    searchedGuides,
    pagePath,
    pageId,
    paths
  }
  
  if (location.search.indexOf('preview=true') !== -1) {
    return <ArticlePreview
      {...addedProps}
      latestIds={latestIds} />
  }
  return <Article
    {...addedProps}
    ignoreAds
    {...addedProps}
    latestIds={latestIds} />
}

const Page = (props) => {
  return <Layout {...props}><Component {...props} /></Layout>
}

export default Page
