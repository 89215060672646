import React, { useContext, useEffect, useState } from 'react'
import { sContext } from '../../context'
import { getLandscapeAsset } from '../../lib/getAssets'
import AnchorTag from './AnchorTag'

const pinterest = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>pinterest-white</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="D-Coloured-background-share" transform="translate(-767.000000, -956.000000)" fill="currentColor">
        <path d="M778.995064,956 C772.360757,956 767,961.370629 767,967.995064 C767,973.079391 770.159194,977.423283 774.621555,979.170712 C774.512958,978.222954 774.424105,976.761826 774.661045,975.725216 C774.878239,974.78733 776.062937,969.762238 776.062937,969.762238 C776.062937,969.762238 775.707528,969.041547 775.707528,967.985191 C775.707528,966.316742 776.675031,965.07281 777.879473,965.07281 C778.906211,965.07281 779.399835,965.842863 779.399835,966.761004 C779.399835,967.787742 778.748252,969.327849 778.402715,970.759358 C778.116413,971.953928 779.004936,972.931304 780.179761,972.931304 C782.312217,972.931304 783.951049,970.680378 783.951049,967.442205 C783.951049,964.569313 781.887701,962.5652 778.935829,962.5652 C775.519951,962.5652 773.515837,965.122172 773.515837,967.767997 C773.515837,968.794735 773.910736,969.900452 774.40436,970.502674 C774.503085,970.621144 774.512958,970.729741 774.48334,970.848211 C774.394488,971.223365 774.187166,972.042781 774.147676,972.210613 C774.098313,972.427807 773.969971,972.47717 773.742904,972.368573 C772.262032,971.657754 771.334019,969.466063 771.334019,967.708762 C771.334019,963.927602 774.078568,960.452489 779.261621,960.452489 C783.417935,960.452489 786.656109,963.414233 786.656109,967.38297 C786.656109,971.519539 784.049774,974.846565 780.436446,974.846565 C779.222131,974.846565 778.076923,974.214726 777.691896,973.464418 C777.691896,973.464418 777.089675,975.754833 776.941588,976.317565 C776.675031,977.364048 775.944467,978.667215 775.450843,979.466886 C776.576306,979.812423 777.761004,980 779.004936,980 C785.629371,980 791,974.629371 791,968.004936 C790.990128,961.370629 785.619498,956 778.995064,956 Z" id="pinterest"></path>
      </g>
    </g>
  </svg>
)

const chains = (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>noun_link_2071800</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="D-Coloured-background-share" transform="translate(-818.000000, -956.000000)" fill="currentColor" fillRule="nonzero">
        <g id="noun_link_2071800" transform="translate(818.000000, 956.000000)">
          <path d="M5.63847733,23.9997067 C7.15077071,23.9498501 8.58292197,23.30777 9.62623733,22.211864 L14.1694507,17.668664 C14.2626791,17.5754338 14.352392,17.4803227 14.4385893,17.3833307 C14.1924999,17.41441 13.9448382,17.4314297 13.6968107,17.4343067 C12.9275451,17.4417536 12.1629136,17.3144863 11.4375333,17.0582667 L7.95509333,20.5407067 C7.35022122,21.2015568 6.50800783,21.5956257 5.61306667,21.6365333 C4.75835826,21.6241161 3.94610016,21.2618864 3.36572,20.63432 C2.73816495,20.0539584 2.37593568,19.2417246 2.36350667,18.3870373 C2.40438723,17.4920729 2.79844796,16.6498287 3.45930667,16.0449333 L8.00252,11.5017333 C8.6074053,10.8408799 9.44964061,10.4468241 10.3445947,10.4059493 C11.1992887,10.4183503 12.0115353,10.7805773 12.5918907,11.4081467 C12.771859,11.5872487 12.9338322,11.7835657 13.0754853,11.99428 C13.2523406,12.0758751 13.4449082,12.1177832 13.6396773,12.117064 C14.145655,12.0702294 14.6150281,11.833198 14.9530667,11.453808 L15.2982667,11.1085947 C15.0200137,10.6045868 14.6714389,10.1427499 14.2630293,9.73697867 C13.2319455,8.66162823 11.8089008,8.05030673 10.3191173,8.04272533 C8.80683117,8.09261409 7.374691,8.73468777 6.33135467,9.83056533 L1.78815467,14.3737653 C0.69225097,15.4171065 0.050159116,16.849265 0.000266666667,18.3615733 C0.00788336511,19.8513513 0.619212732,21.2743816 1.694552,22.3054693 C2.72565364,23.3808 4.14869581,23.9921126 5.63847733,23.9997067 Z" id="Path"></path>
          <path d="M23.9997333,5.63846133 C23.9921161,4.14868128 23.3807938,2.72564726 22.305464,1.69454667 C21.2743816,0.619186243 19.8513285,0.00786318316 18.3615387,0.000293333333 C16.8492507,0.0501735321 15.417108,0.692249757 14.3737787,1.788136 L9.83057867,6.331336 C9.73734844,6.42458222 9.64762933,6.51970489 9.56142133,6.616704 C9.80751027,6.5856164 10.055172,6.56858964 10.3032,6.56570667 C11.0724776,6.5582335 11.8371241,6.6854959 12.562512,6.94173067 L16.0449387,3.45929333 C16.6498207,2.79843692 17.4920565,2.4043805 18.3870107,2.36350933 C19.2417072,2.3759024 20.0539576,2.73813069 20.6343093,3.36570667 C21.2618565,3.94607908 21.624079,4.75831643 21.6365067,5.61300533 C21.5956286,6.50795781 21.2015733,7.35019109 20.5407227,7.95507733 L15.9975227,12.4982773 C15.3926458,13.1591157 14.5504346,13.5531745 13.6554987,13.5940773 C12.8007882,13.5816636 11.9885257,13.2194403 11.408136,12.59188 C11.2281452,12.4127739 11.0661555,12.2164456 10.9244933,12.0057147 C10.747711,11.9241467 10.5552196,11.8822547 10.360528,11.8829787 C9.85449226,11.9297472 9.38504808,12.1667699 9.04697067,12.5461947 L8.70174133,12.89144 C8.98000342,13.3954363 9.32857743,13.8572658 9.73697867,14.2630373 C10.7680858,15.3383652 12.1911261,15.9496819 13.6809093,15.9572907 C15.1932062,15.9074174 16.625358,15.2653349 17.6686853,14.1694347 L22.2118853,9.62623467 C23.3077677,8.58289654 23.9498444,7.15075219 23.9997333,5.63846133 L23.9997333,5.63846133 Z" id="Path"></path>
        </g>
      </g>
    </g>
  </svg>
)

const facebook = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z"/>
  </svg>
)

const twitter = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
  </svg>
)

const SocialShare = ({ pages }) => {
  const localePath = useContext(sContext).currentLocale
  const location = useContext(sContext).location

  const [state, setState] = useState({
    description: '',
    image: null
  })

  const getDescription = () => {
    try {
      const description = document
        .querySelector('meta[name="twitter:description"]')
        .getAttribute('content')

      let asset

      const {
        image: banner,
        overrideBanner,
        topType,
        inArticleAsset,
        landscapeDesktopAsset
      } = pages.content.content[0].data.target.fields

      if(!inArticleAsset && (!banner && !overrideBanner)) {
        asset = pages.content.content[0].data.target.fields.subSections[0].fields
        asset = asset.overrideBanner ?
          asset.overrideBanner.fields.file.url : asset.banner.fields.file.url
          
      } else {
        asset = getLandscapeAsset({
          banner,
          overrideBanner,
          topType,
          inArticleAsset,
          landscapeDesktopAsset
        })

        asset = asset?.file?.url
      }
  
      setState({
        description,
        asset
      })

    } catch (e) {
      console.log('Error', e)
    }
    return true
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if(getDescription()) {
        clearInterval(interval)
      }
    }, 100)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const {
    description,
    asset
  } = state

  let type
  if(pages.category) {
    type = pages.category.fields.path

  } else if(location.pathname.includes('/top-10/')) {
    type = 'top-10'

  } else {
    type = 'article'
  }

  let currentUrl = `https://enroute.aircanada.com/${localePath}/${type}/${pages.path}/`;



  const chainAriaLabel = localePath === 'en' ? 'Copy url' : 'Copier l\'url';

  return (
    <ul className="cSocialShare-actions">
      <li className="cSocialShare-action">
        <AnchorTag
          path={`https://facebook.com/sharer/sharer.php?u=${currentUrl}`}
          attributes={{
            className: 'cSocialShare-link',
            'aria-label': 'Air Canada enRoute Facebook'
          }} >
          {facebook}
        </AnchorTag>
      </li>
      <li className="cSocialShare-action">

        <AnchorTag
          path={
            'https://twitter.com/intent/tweet?url=' +
            currentUrl +
            '&via=enroutemag&text=' +
            encodeURIComponent(description)
          }
          attributes={{
            className: 'cSocialShare-link',
            'aria-label': 'Air Canada enRoute Twitter'
          }} >
          {twitter}
          </AnchorTag>
      </li>
      <li className="cSocialShare-action">
        <AnchorTag
          path={
            'https://pinterest.com/pin/create/button/?url=' +
            currentUrl +
            '&media=' +
            encodeURIComponent(asset) +
            '&description=' +
            encodeURIComponent(description)
          }
          attributes={{
            className: 'cSocialShare-link',
            'aria-label': 'Air Canada enRoute Pinterest'
          }} >
          {pinterest}
        </AnchorTag>
      </li>
      <li className="cSocialShare-action">
        <button
          className="cSocialShare-link"
          aria-label={chainAriaLabel}
          onClick={() => {
            if(typeof document !== 'undefined') {
              const dummy = document.createElement('textarea')
              document.body.appendChild(dummy)
              dummy.value = window.location.href
              dummy.select()
              document.execCommand('copy')
              document.body.removeChild(dummy)
              const alertCopy =
                localePath === 'en'
                  ? 'Link has been copied to the clipboard!'
                  : 'Le lien a été copié!'
              alert(alertCopy)
            }
          }} >
          {chains}
        </button>
      </li>
    </ul>
  )
}

export default SocialShare